import React, { useContext, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, CircularProgress, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { AdminContext } from '../../../../../../context/AdminContext';
import SelectDialog from './SelectDialog';

function ResourceDialog({ targetResource, courseId, lessonId, dialogOpen, handleClose, onFormSubmited }) {

    const { token } = useContext(AdminContext);
    const [removedQuestions, setRemovedQuestions] = useState([]);
    const [isUploading, setUploading] = useState(false);
    const [selectDialogState, setSelectDialogState] = useState(
        {
            open: false,
            onSubmitted: (selected) => { }
        }
    );

    const handleSelectDialogClose = () => {
        setSelectDialogState({
            open: false,
            onSubmitted: (selected) => { }
        });
    };

    var initialValues = {
        title: targetResource?.title ? targetResource.title : "",
        type: targetResource?.type !== undefined && targetResource.type !== null ? targetResource.type : "",
        external_link: targetResource?.external_link ? targetResource.external_link : "",
        source: null,
        source_link: targetResource?.source_link ? targetResource.source_link : "",
        reading_id: targetResource?.reading_id ? targetResource.reading_id : "",
        word_set_id: targetResource?.word_set_id ? targetResource.word_set_id : "",
        questions: targetResource?.questions ? targetResource.questions : [
            {
                question: "",
                a: "",
                a_tip: "",
                b: "",
                b_tip: "",
                c: "",
                c_tip: "",
                d: "",
                d_tip: "",
                answer: ""
            }
        ]
    };

    var validationObject = {
        title: yup.string().required("Field is required"),
        type: yup.number().required("Field is required"),
        // external_link is required if type is 0
        external_link: yup.string().when('type', {
            is: (type) => type === 0,
            then: () => yup.string().required("Field is required")
        }),
        // source is required if type is 1 and source_link is empty
        source: yup.mixed().nullable().when(['source_link', 'type'], {
            is: (source_link, type) => (!source_link || source_link === "") && type === 1,
            then: () => yup.mixed().nonNullable("").required('Field is required')
        }),
        source_link: yup.string(),
        // word_set_id is required if type is 2
        word_set_id: yup.number().when('type', {
            is: (type) => type === 2,
            then: () => yup.number().required("Field is required")
        }),
        // reading_id is required if type is 3
        reading_id: yup.number().when('type', {
            is: (type) => type === 3,
            then: () => yup.number().required("Field is required")
        }),
        // questions is required if type is 4
        questions: yup.array().when('type', {
            is: (type) => type === 4,
            then: () => yup.array().of(
                yup.object().shape({
                    id: yup.number().nullable(),
                    question: yup.string().required("Field is required"),
                    a: yup.string().required("Field is required"),
                    a_tip: yup.string(),
                    b: yup.string().required("Field is required"),
                    b_tip: yup.string(),
                    c: yup.string(),
                    c_tip: yup.string(),
                    d: yup.string(),
                    d_tip: yup.string(),
                    answer: yup.string().required("Field is required")
                })
            ).min(1, "At least one question is required")
        })
    };

    const lessonSchema = yup.object().shape(validationObject);

    const submitForm = async (values) => {
        setUploading(true);
        if (values.source) {
            await axios.post(`${process.env.REACT_APP_API_URL}course/uploadSourceContent?token=${token}`, {
                sourceContent: values.source
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        values.source_path = response.data.sourceContentPath;
                    }
                });
        }

        let source = { ...values };
        source.type = targetResource.type;
        delete source.source;
        delete source.source_link;
        delete source.questions;

        if (source.type !== 0) {
            delete source.external_link;
        }

        if (source.type !== 2) {
            delete source.word_set_id;
        }

        if (source.type !== 3) {
            delete source.reading_id;
        }

        console.log(source);

        if (targetResource?.id) {
            //update

            let updatedQuestions = []
            let newQuestions = []

            if (targetResource.type === 4) {
                updatedQuestions = values.questions.map((question) => {
                    if (question.id) {
                        let questionToCompare = targetResource.questions.find((q) => q.id === question.id);
                        if (!(questionToCompare.question === question.question && questionToCompare.a === question.a && questionToCompare.b === question.b && questionToCompare.c === question.c && questionToCompare.d === question.d && questionToCompare.a_tip === question.a_tip && questionToCompare.b_tip === question.b_tip && questionToCompare.c_tip === question.c_tip && questionToCompare.d_tip === question.d_tip && questionToCompare.answer === question.answer)) {
                            return question;
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                }).filter((question) => question !== null);

                newQuestions = values.questions.filter((question) => !question.id);
            }

            await axios.patch(`${process.env.REACT_APP_API_URL}course/updateSource?sourceId=${targetResource.id}&token=${token}`, {
                source: source,
                questionsToRemove: removedQuestions,
                questionsToUpdate: updatedQuestions,
                questionsToAdd: newQuestions
            }).catch((err) => {
                console.log("err: " + err);
                setUploading(false);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    let newSource = { ...targetResource, ...source };
                    let allQuestions = [
                        ...(newSource.questions.filter((question) => !removedQuestions.includes(question.id))).map((question) => {
                            let updatedQuestion = updatedQuestions.find((q) => q.id === question.id);
                            if (updatedQuestion) {
                                return updatedQuestion;
                            } else {
                                return question;
                            }
                        }),
                        ...(response.data.questions || []),
                    ]
                    newSource.questions = [
                        ...allQuestions
                    ]
                    if (newSource.source_path) {
                        newSource.source_link = `${process.env.REACT_APP_WEB_URL}${newSource.source_path}`;
                    }
                    setUploading(false);
                    onFormSubmited(newSource);
                }
            });

        } else {
            //add

            await axios.post(`${process.env.REACT_APP_API_URL}course/createSource?token=${token}`, {
                source: { ...source, course_id: courseId, lesson_id: lessonId },
                questions: values.questions || []
            }).catch((err) => {
                console.log("err: " + err);
                setUploading(false);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    let newSource = { ...source, id: response.data.sourceId };
                    newSource.questions = response.data.questions;
                    setUploading(false);
                    onFormSubmited(newSource);
                }
            });
        }

    }

    return (
        <>
            {selectDialogState?.open &&
                <SelectDialog
                    dialogOpen={selectDialogState.open}
                    handleClose={handleSelectDialogClose}
                    type={targetResource?.type}
                    onSubmitted={selectDialogState.onSubmitted}
                />}
            <Dialog
                sx={{ margin: "0 auto" }}
                fullWidth
                open={dialogOpen}
                onClose={handleClose}>
                <Box m="20px" textAlign="center">

                    <Typography variant='h3'>
                        {targetResource?.id ? "Edit Resource" : "Add Resource"}
                    </Typography>

                </Box>
                <DialogContent>
                    <Box>
                        <Formik
                            onSubmit={submitForm}
                            initialValues={initialValues}
                            validationSchema={lessonSchema}
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="75px" mb="25px">

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Title"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"title"}
                                            value={values.title}
                                            error={!!touched.title && !!errors.title}
                                            helperText={touched.title && errors.title}
                                            sx={{ gridColumn: "span 4" }}
                                        />

                                        {
                                            values.type === 0 &&
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="Link"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"external_link"}
                                                value={values.external_link}
                                                error={!!touched.external_link && !!errors.external_link}
                                                helperText={touched.external_link && errors.external_link}
                                                sx={{ gridColumn: "span 4" }}
                                            />
                                        }

                                        {
                                            values.type === 1 &&
                                            <>
                                                <Typography variant='h6' sx={{ gridColumn: "span 4", marginTop: "20px" }}>Upload Document (PDF/Word/PowerPoint/Image)</Typography>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    type="file"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        setFieldValue("source", e.target.files[0]);
                                                    }}
                                                    name={"source"}
                                                    error={!!touched.source && !!errors.source}
                                                    helperText={touched.source && errors.source}
                                                    //pdf, images, doc, docx, pptx
                                                    inputProps={{ accept: ".pdf,.doc,.docx,.pptx,.ppt,.jpg,.jpeg,.png" }}
                                                    sx={{ gridColumn: "span 4" }}
                                                />
                                            </>
                                        }

                                        {
                                            (values.type === 2 || values.type === 3) &&
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                sx={{ gridColumn: "span 4" }}
                                                onClick={() => {
                                                    setSelectDialogState({
                                                        open: true,
                                                        onSubmitted: (selected) => {
                                                            if (values.type === 3) {
                                                                console.log(selected);
                                                                console.log(selected);
                                                                console.log(selected);
                                                                setFieldValue("reading_id", selected.id);
                                                                if (!(values.title)) {
                                                                    setFieldValue("title", selected.title);
                                                                }
                                                            } else {
                                                                if (!(values.title)) {
                                                                    setFieldValue("title", selected.name);
                                                                }
                                                                setFieldValue("word_set_id", selected.id);
                                                            }
                                                        }
                                                    });
                                                }}
                                            >
                                                <Typography variant="button" color="primary" fontSize={10}>
                                                    {
                                                        values.reading_id || values.word_set_id ? "Selected" : "Select " + (values.type === 3 ? "Reading" : "Wordset")
                                                    }
                                                </Typography>
                                            </Button>
                                        }

                                        {
                                            values.type === 4 &&
                                            <Box sx={{ gridColumn: "span 4" }}>
                                                {
                                                    values.questions.map((question, index) => (
                                                        <Box key={index} display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr)">
                                                            <TextField
                                                                fullWidth
                                                                variant="filled"
                                                                type="text"
                                                                label="Question"
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    setFieldValue(`questions[${index}].question`, e.target.value);
                                                                }}
                                                                name={`questions[${index}].question`}
                                                                value={question.question}
                                                                error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].question}
                                                                helperText={touched.questions && errors.questions && errors.questions[index] && errors.questions[index].question}
                                                                sx={{ gridColumn: "span 4" }}
                                                            />

                                                            <TextField
                                                                fullWidth
                                                                variant="filled"
                                                                type="text"
                                                                label="A"
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    setFieldValue(`questions[${index}].a`, e.target.value);
                                                                }}
                                                                name={`questions[${index}].a`}
                                                                value={question.a}
                                                                error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].a}
                                                            />

                                                            <TextField
                                                                fullWidth
                                                                variant="filled"
                                                                type="text"
                                                                label="B"
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    setFieldValue(`questions[${index}].b`, e.target.value);
                                                                }}
                                                                name={`questions[${index}].b`}
                                                                value={question.b}
                                                                error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].b}
                                                            />

                                                            <TextField
                                                                fullWidth
                                                                variant="filled"
                                                                type="text"
                                                                label="C"
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    setFieldValue(`questions[${index}].c`, e.target.value);
                                                                }}
                                                                name={`questions[${index}].c`}
                                                                value={question.c}
                                                                error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].c}
                                                            />

                                                            <TextField
                                                                fullWidth
                                                                variant="filled"
                                                                type="text"
                                                                label="D"
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    setFieldValue(`questions[${index}].d`, e.target.value);
                                                                }}
                                                                name={`questions[${index}].d`}
                                                                value={question.d}
                                                                error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].d}
                                                            />

                                                            <TextField
                                                                fullWidth
                                                                variant="filled"
                                                                type="text"
                                                                label="A Tip"
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    setFieldValue(`questions[${index}].a_tip`, e.target.value);
                                                                }}
                                                                name={`questions[${index}].a_tip`}
                                                                value={question.a_tip}
                                                                error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].a_tip}
                                                            />

                                                            <TextField
                                                                fullWidth
                                                                variant="filled"
                                                                type="text"
                                                                label="B Tip"
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    setFieldValue(`questions[${index}].b_tip`, e.target.value);
                                                                }}
                                                                name={`questions[${index}].b_tip`}
                                                                value={question.b_tip}
                                                                error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].b_tip}
                                                            />

                                                            <TextField
                                                                fullWidth
                                                                variant="filled"
                                                                type="text"
                                                                label="C Tip"
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    setFieldValue(`questions[${index}].c_tip`, e.target.value);
                                                                }}
                                                                name={`questions[${index}].c_tip`}
                                                                value={question.c_tip}
                                                                error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].c_tip}
                                                            />

                                                            <TextField
                                                                fullWidth
                                                                variant="filled"
                                                                type="text"
                                                                label="D Tip"
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    setFieldValue(`questions[${index}].d_tip`, e.target.value);
                                                                }}
                                                                name={`questions[${index}].d_tip`}
                                                                value={question.d_tip}
                                                                error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].d_tip}
                                                            />
                                                            <FormControl fullWidth variant="filled">
                                                                <InputLabel id="answer-label">Answer</InputLabel>
                                                                <Select
                                                                    labelId="answer-label"
                                                                    label="Answer"
                                                                    onBlur={handleBlur}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`questions[${index}].answer`, e.target.value);
                                                                    }}
                                                                    name={`questions[${index}].answer`}
                                                                    value={question.answer}
                                                                    error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].answer}
                                                                >
                                                                    <MenuItem value={"a"}>A</MenuItem>
                                                                    <MenuItem value={"b"}>B</MenuItem>
                                                                    <MenuItem value={"c"}>C</MenuItem>
                                                                    <MenuItem value={"d"}>D</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            <Box display="flex" alignItems="center" gap="10px" sx={{ gridColumn: "span 4" }}>
                                                                {
                                                                    (values.questions.length > 1) &&
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="error"
                                                                        onClick={() => {
                                                                            let newQuestions = values.questions;
                                                                            newQuestions[index].id && setRemovedQuestions([...removedQuestions, newQuestions[index].id]);
                                                                            newQuestions.splice(index, 1);
                                                                            setFieldValue("questions", newQuestions);
                                                                        }
                                                                        }
                                                                    >
                                                                        Remove Question
                                                                    </Button>
                                                                }
                                                                {(index === values.questions.length - 1) &&
                                                                    <Button
                                                                        variant="outlined"
                                                                        color="success"
                                                                        onClick={() => {
                                                                            setFieldValue("questions", [...values.questions, {
                                                                                question: "",
                                                                                a: "",
                                                                                a_tip: "",
                                                                                b: "",
                                                                                b_tip: "",
                                                                                c: "",
                                                                                c_tip: "",
                                                                                d: "",
                                                                                d_tip: "",
                                                                                answer: ""
                                                                            }]);
                                                                        }
                                                                        }
                                                                    >
                                                                        Add Question
                                                                    </Button>
                                                                }
                                                            </Box>
                                                            {index !== values.questions.length - 1 &&
                                                                <Box component="hr" height="1px" sx={{ gridColumn: "span 4", mt: "30px", mb: "40px" }} width="100%" />
                                                            }
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                        }

                                    </Box>
                                    <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                        <Button
                                            type="submit"
                                            color="secondary"
                                            variant="contained"
                                            disabled={isUploading}
                                            onClick={() => console.log(errors)}>
                                            <Typography variant='h5'>Save</Typography>
                                            {
                                                isUploading &&
                                                <CircularProgress size={16} sx={{ ml: "10px" }} />
                                            }
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </DialogContent>
            </Dialog >
        </>
    )
}

export default ResourceDialog