import { Box, Menu, MenuItem } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import AdSettingsDialog from './AdSettingsDialog';
import VersionSettingsDialog from './VersionSettingsDialog';
import LinkSettingsDialog from './LinkSettingsDialog';
import GoldWinnerCountDialog from './GoldWinnerCountDialog';
// import LoyaltyPrizeSettingsDialog from './LoyaltyPrizeSettingsDialog';

function SettingsMenu({ open, anchorEl, handleClose }) {

    const [settings, setSettings] = useState(null);
    const [adSettingsDialogOpen, setAdSettingsDialogOpen] = useState(false);
    const [versionSettingsDialogOpen, setVersionSettingsDialogOpen] = useState(false);
    const [linkSettingsDialogOpen, setLinkSettingsDialogOpen] = useState(false);
    const [goldWinnerDialogOpen, setGoldWinnerDialogOpen] = useState(false);
    // const [loyaltyPrizeDialogOpen, setLoyaltyPrizeDialogOpen] = useState(false);

    const fetchSettings = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}settings/getAppSettingsForAllLanguages`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setSettings(response.data.appSettings);
            }
        });
    }

    useEffect(() => {
        fetchSettings();
    }, [])


    return (
        <Box>
            <AdSettingsDialog dialogOpen={adSettingsDialogOpen} handleClose={() => setAdSettingsDialogOpen(false)} settings={settings} fetchSettings={fetchSettings} />
            <VersionSettingsDialog dialogOpen={versionSettingsDialogOpen} handleClose={() => setVersionSettingsDialogOpen(false)} settings={settings} fetchSettings={fetchSettings} />
            <LinkSettingsDialog dialogOpen={linkSettingsDialogOpen} handleClose={() => setLinkSettingsDialogOpen(false)} settings={settings} fetchSettings={fetchSettings} />
            <GoldWinnerCountDialog dialogOpen={goldWinnerDialogOpen} handleClose={() => setGoldWinnerDialogOpen(false)} settings={settings} fetchSettings={fetchSettings} />
            {/* <LoyaltyPrizeSettingsDialog dialogOpen={loyaltyPrizeDialogOpen} handleClose={() => setLoyaltyPrizeDialogOpen(false)} settings={settings} fetchSettings={fetchSettings} /> */}
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ mt: "40px" }}
            >
                <MenuItem onClick={() => { setAdSettingsDialogOpen(true); handleClose(); }}>
                    Ad Settings
                </MenuItem>
                <MenuItem onClick={() => { setVersionSettingsDialogOpen(true); handleClose(); }}>
                    Version Settings
                </MenuItem>
                <MenuItem onClick={() => { setLinkSettingsDialogOpen(true); handleClose(); }}>
                    Link Settings
                </MenuItem>
                <MenuItem onClick={() => { setGoldWinnerDialogOpen(true); handleClose(); }}>
                    Set Gold Winner Count
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); window.open(`${process.env.REACT_APP_WEB_URL}gold-settings`, '_self'); }}>
                    Set Gold Earnings
                </MenuItem>
                {/* <MenuItem onClick={() => { setLoyaltyPrizeDialogOpen(true); handleClose(); }}>
                    Set Loyalty System Prize
                </MenuItem> */}
            </Menu>
        </Box>

    )
}

export default SettingsMenu