import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useContext } from 'react'
import axios from 'axios';
import { AdminContext } from '../../../../context/AdminContext';

function SectionRemoveDialog({ sectionId, removeDialogOpen, handleRemoveDialogClose, onCourseSectionRemoved }) {
    const { token } = useContext(AdminContext);

    const removeCourseSection = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}course/deleteCourseSection?sectionId=${sectionId}&token=${token}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                onCourseSectionRemoved();
            }
        });
    }

    return (
        <Dialog
            open={removeDialogOpen}
            keepMounted
            onClose={handleRemoveDialogClose}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{"Are you sure you want to delete the section?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You cannot undo this operation but you can add new course section if you wish. Lessons in this section will be deleted as well.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                <Button onClick={() => removeCourseSection()}>Delete</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SectionRemoveDialog