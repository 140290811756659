import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';

function SubCategoryDialog({ subCategory, dialogOpen, handleClose, handleFormSubmit }) {
    const colors = tokens()

    var initialValues = {
        name: subCategory?.name ? subCategory?.name : "",
        ...(!subCategory || subCategory.language === "turkish" ?
            {
                thumbnail: null,
                thumbnail_link: subCategory?.thumbnail_link ? subCategory?.thumbnail_link : ""
            } :
            {}
        ),
        category: subCategory && subCategory.category ? subCategory.category : "",
        is_pro: (subCategory && subCategory.is_pro !== undefined && subCategory.is_pro !== null) ? subCategory.is_pro : ""
    }

    var validationObject = {
        name: yup.string().required("required"),
        ...(!subCategory || subCategory.language === "turkish" ?
            {
                category: yup.number().required("required"),
                is_pro: yup.number().required("required"),
                thumbnail_link: yup.string(),
                thumbnail: yup.mixed().nullable()
            } :
            {}
        )

    }

    const subCategorySchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                {subCategory && subCategory.language === "turkish" &&
                    <Typography variant='h3'>
                        Edit Subcategory
                    </Typography>
                }
                {subCategory && subCategory.language !== "turkish" &&
                    <Typography variant='h3'>
                        Edit Subcategory Translation
                    </Typography>
                }
                {!subCategory &&
                    <Typography variant='h3'>
                        New Subcategory
                    </Typography>
                }
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={subCategorySchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="75px" mb="25px">
                                    {(!subCategory || subCategory.language === "turkish") &&
                                        <Box textAlign="center" sx={{ gridColumn: "span 4", marginTop: "20px" }}>
                                            <Typography variant="h6">Thumbnail Image</Typography>
                                            <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                                {(values.thumbnail || (values.thumbnail_link && values.thumbnail_link !== "")) && <img alt='thumbnail' style={{ maxHeight: "140px", maxWidth: "140px" }} src={values.thumbnail ? URL.createObjectURL(values.thumbnail) : values.thumbnail_link} />}
                                                <input id="thumbnail" name="thumbnail" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail", event.target.files[0]); }} hidden />
                                            </Button>
                                        </Box>
                                    }
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"name"}
                                        value={values.name}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                    />
                                    {(!subCategory || subCategory.language === "turkish") &&
                                        <FormControl variant="filled" sx={{ minWidth: "200px", gridColumn: "span 2" }}>
                                            <InputLabel id="filter-label">Category</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                label="Category"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"category"}
                                                value={values.category}
                                                error={!!touched.category && !!errors.category}
                                            >
                                                <MenuItem value={1}>A1</MenuItem>
                                                <MenuItem value={2}>A2</MenuItem>
                                                <MenuItem value={3}>B1</MenuItem>
                                                <MenuItem value={4}>B2</MenuItem>
                                                <MenuItem value={5}>C1</MenuItem>
                                                <MenuItem value={6}>C2</MenuItem>
                                                <MenuItem value={7}>Academic</MenuItem>
                                                <MenuItem value={14}>Phrasal Verbs</MenuItem>
                                                <MenuItem value={15}>Idioms</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                    {(!subCategory || subCategory.language === "turkish") &&
                                        <FormControl variant="filled" sx={{ minWidth: "200px", gridColumn: "span 2" }}>
                                            <InputLabel id="filter-label">Usage Limit</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                label="Usage Limit"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"is_pro"}
                                                value={values.is_pro}
                                                error={!!touched.is_pro && !!errors.is_pro}
                                            >
                                                <MenuItem value={0}>Everyone</MenuItem>
                                                <MenuItem value={1}>Premium Users</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                </Box>
                                <Box display="flex" justifyContent={!subCategory || subCategory.language === "turkish" ? "end" : "center"} m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default SubCategoryDialog