import { Box, Button, FormControl, IconButton, InputLabel, OutlinedInput } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from '../../theme';
import Header from '../../global/Header';
import { useNavigate } from 'react-router-dom';
import CourseDialog from './CourseDialog';
import CourseRemoveDialog from './CourseRemoveDialog';

function CoursesPage() {
    const colors = tokens();
    const [isLoading, setLoading] = useState(true);
    const [searchKey, setSearchKey] = useState("");
    const [orderModel, setOrderModel] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [courses, setCourses] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [targetCourse, setTargetCourse] = useState(null);
    const [courseDialogOpen, setCourseDialogOpen] = useState(false);

    const navigate = useNavigate();

    const [courseToRemove, setCourseToRemove] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    const columns = [
        {
            field: 'id',
            headerName: 'ID'
        },
        {
            field: 'thumbnail_link',
            headerName: "THUMBNAIL",
            sortable: false,
            renderCell: (params) => <img src={params.value} alt='thumbnail' style={{ height: "50px", width: "50px" }} />
        },
        {
            field: 'title',
            headerName: 'TITLE',
            flex: 1,
        }, {
            field: 'description',
            headerName: 'DESCRIPTION',
        }, {
            field: 'details',
            headerName: 'DETAILS',
        }, {
            field: 'type',
            headerName: 'TYPE',
            renderCell: ({ row: { type } }) => {
                switch (type) {
                    case 0:
                        return "Exam Preparation Courses";
                    case 1:
                        return "Vocabulary Courses";
                    case 2:
                        return "Grammar Courses";
                    case 3:
                        return "Reading Courses";
                    case 4:
                        return "Listening Courses";
                    default:
                        return "Unknown";
                }
            }
        }, {
            field: 'rating',
            headerName: 'RATING (COUNT)',
            renderCell: ({ row: { total_rating, rating_count } }) => {
                return (rating_count > 0 ? total_rating / rating_count : 0) + " (" + rating_count + ")";
            }
        }, {
            field: 'saved_count',
            headerName: 'SAVE COUNT'
        }, {
            field: 'enrolled_count',
            headerName: 'ENROLL COUNT'
        }, {
            field: 'lesson_count',
            headerName: 'LESSON COUNT'
        }, {
            field: 'is_pro',
            headerName: 'PRO',
            type: 'boolean'
        }, {
            field: 'language',
            headerName: 'LANGUAGE',
            renderCell: ({ row: { language } }) => {
                return language.charAt(0).toUpperCase() + language.slice(1);
            }
        }, {
            field: "created_date", headerName: "CREATED AT",
            renderCell: ({ row: { created_date } }) => {
                return new Date(created_date).toLocaleDateString()
            }
        }, {
            field: 'actions',
            type: 'actions',
            sortable: false,
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<InfoIcon />}
                    label="See Details"
                    onClick={() => navigate(`/courses/${params.id}`)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit Course"
                    onClick={() => setTargetCourse(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete Course"
                    onClick={() => setCourseToRemove(params.row)}
                    showInMenu
                />
            ],
        }
    ];

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}course/getCourses?startIndex=${paginationModel.pageSize * paginationModel.page}&limit=${paginationModel.pageSize}${searchKey ? `&searchKey=${searchKey}` : ""}${orderModel ? `&orderColumn=${orderModel.field}&orderType=${orderModel.sort}` : ""}`)
            .catch((err) => {
                setCourses([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCourses(response.data.courses);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }, [paginationModel, orderModel]);

    useEffect(() => {
        if (targetCourse) {
            setCourseDialogOpen(true);
        }
    }, [targetCourse]);

    useEffect(() => {
        if (courseToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [courseToRemove]);

    return (
        <Box sx={{ p: "75px" }}>
            <CourseRemoveDialog
                courseId={courseToRemove?.id}
                removeDialogOpen={removeDialogOpen}
                handleRemoveDialogClose={() => {
                    setRemoveDialogOpen(false);
                    setCourseToRemove(null);
                }}
                onCourseRemoved={() => {
                    setRemoveDialogOpen(false);
                    setCourseToRemove(null);
                    setPaginationModel(prev => { return { ...prev, page: 0 } });
                }}
            />
            <CourseDialog
                targetCourse={targetCourse}
                dialogOpen={courseDialogOpen}
                handleClose={() => {
                    setCourseDialogOpen(false)
                    setTargetCourse(null);
                }}
                onFormSubmited={() => {
                    setCourseDialogOpen(false);
                    setTargetCourse(null);
                    setPaginationModel(prev => { return { ...prev, page: 0 } })
                }} />

            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Courses" subtitle="You can see and manage courses from this page." />
                    <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                        setCourseDialogOpen(true);
                    }} >
                        Add New Course
                    </Button>
                    <Button variant='contained' color='warning' sx={{ m: 1 }} onClick={() => {
                        window.open(`${process.env.REACT_APP_WEB_URL}course-categories`, '_self');
                    }} >
                        Course Categories
                    </Button>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Search</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='text'
                            onChange={(e) => {
                                setSearchKey(e.target.value ? e.target.value : "");
                            }}
                            label="Search"
                        />
                    </FormControl>
                    <IconButton
                        aria-label="search button"
                        sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px" }}
                        onClick={() => { setPaginationModel(prev => { return { ...prev, page: 0 } }) }}
                        edge="end"
                    >
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
            <DataGrid
                rows={courses}
                columns={columns}
                rowCount={rowCount}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 75, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode='server'
                onSortModelChange={(model) => {
                    setOrderModel((prev) => model && model.length > 0 ? model[0] : null);
                    setPaginationModel(prev => { return { ...prev, page: 0 } });
                }}
                disableColumnFilter
                sx={{
                    ".MuiDataGrid-columnHeaders": {
                        background: colors.grey[900]
                    }
                }}
            />
        </Box>
    )
}

export default CoursesPage