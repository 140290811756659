import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../global/Header';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { AdminContext } from '../../../context/AdminContext';
import BookBox from './BookBox';
import BookDialog from './BookDialog';

function BooksPage() {
    const { token } = useContext(AdminContext);
    const [searchKey, setSearchKey] = useState("");
    const [books, setBooks] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [bookToRemove, setBookToRemove] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [targetBook, setTargetBook] = useState(null);
    const [bookDialogOpen, setBookDialogOpen] = useState(false);

    const fetchBooks = () => {
        axios.get(`${process.env.REACT_APP_API_URL}reading/getBooks?searchKey=${searchKey}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                console.log("err: " + err);
                setBooks([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.books && response.data.books.length === 50);
                    if (startIndex === 0) {
                        console.log(response.data.books);
                        setBooks(response.data.books);
                    } else {
                        setBooks((prev) => [...prev, ...response.data.books]);
                    }
                }
            });
    }

    useEffect(() => {
        fetchBooks();
    }, [startIndex]);

    const handleRemoveDialogClose = async () => {
        setBookToRemove(null);
        setRemoveDialogOpen(false);
    }

    const removeReadingExamCategory = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}reading/deleteReadingExamCategory?bookId=${bookToRemove.id}&token=${token}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                if (startIndex === 0) {
                    fetchBooks();
                } else {
                    setStartIndex(0);
                }
                handleRemoveDialogClose();
            }
        });
    }

    useEffect(() => {
        if (bookToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [bookToRemove])

    useEffect(() => {
        if (targetBook) {
            setBookDialogOpen(true);
        }
    }, [targetBook])

    return (
        <Box sx={{ p: "75px" }}>
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Are you sure you want to delete the book?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Readings that are part of this book will also be deleted! You cannot undo this action, but you can add a new book if you wish.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeReadingExamCategory()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <BookDialog targetBook={targetBook} dialogOpen={bookDialogOpen} handleClose={(changed) => {
                setTargetBook(null);
                setBookDialogOpen(false);
                if (changed) {
                    if (startIndex === 0) {
                        fetchBooks();
                    } else {
                        setStartIndex(0);
                    }
                }

            }} />
            <Box display="flex" alignItems="center" gap="20px">

                <Header title="Books" subtitle="You can see and manage books from this page." />

                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="search-box">Search</InputLabel>
                    <OutlinedInput
                        id="search-box"
                        type='text'
                        onChange={(e) => {
                            setSearchKey(e.target.value ? e.target.value : "");
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search button"
                                    onClick={() => {
                                        setBooks([]);
                                        if (startIndex === 0) {
                                            fetchBooks();
                                        } else {
                                            setStartIndex(0);
                                        }
                                    }}
                                    edge="end"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Search"
                    />
                </FormControl>
                <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                    setTargetBook(null);
                    setBookDialogOpen(true);
                }} >
                    Add New Book
                </Button>
            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={books.length}
                    next={() => {
                        setStartIndex(books.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You are viewing all books.</b>
                        </p>
                    }
                >
                    <Box display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                        {books.map(book => <BookBox key={book.id} book={book} editBook={(rc) => setTargetBook(rc)} removeBook={(rc) => setBookToRemove(rc)} />)}
                    </Box>
                </InfiniteScroll>
            </Box>
        </Box>
    )
}

export default BooksPage
