import React, { useContext } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { AdminContext } from '../../../../context/AdminContext';

function SectionDialog({ course, targetSection, dialogOpen, handleClose, onFormSubmited }) {

    const { token } = useContext(AdminContext);

    var initialValues = {
        title: targetSection?.title ? targetSection.title : ""
    };

    var validationObject = {
        title: yup.string().required("Field is required")
    };

    const sectionSchema = yup.object().shape(validationObject);

    const submitForm = async (values) => {

        let section = { ...values };

        if (targetSection) {
            //update

            await axios.patch(`${process.env.REACT_APP_API_URL}course/updateCourseSection?sectionId=${targetSection.id}&token=${token}`, {
                ...section
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    onFormSubmited({ ...section, id: targetSection.id });
                }
            });

        } else {
            //add
            section.position = course.sections.length + 1;
            section.course_id = course.id;
            await axios.post(`${process.env.REACT_APP_API_URL}course/createCourseSection?token=${token}`, {
                ...section
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    onFormSubmited();
                }
            });
        }

    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}>
            <DialogTitle>
                {targetSection ? "Edit Section" : "Add Section"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To {targetSection ? "edit" : "add"} a new section, please enter the title here.
                </DialogContentText>
                <Box>
                    <Formik
                        onSubmit={submitForm}
                        initialValues={initialValues}
                        validationSchema={sectionSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Box>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label="Title"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        Save
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default SectionDialog