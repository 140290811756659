import { Box, Typography } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { tokens } from '../../theme';
import Header from '../../global/Header';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LoginIcon from '@mui/icons-material/Login';
import { CastForEducation, ChromeReaderMode, Dataset, QuizOutlined, Translate } from '@mui/icons-material';
import { AdminContext } from '../../context/AdminContext';

function DashboardPage() {
    const colors = tokens();
    const { token } = useContext(AdminContext);
    const [isLoading, setLoading] = useState(true);
    const [userStatistics, setUserStatistics] = useState({
        total_registration_count: 0,
        today_registration_count: 0,
        this_week_registration_count: 0,
        this_month_registration_count: 0,
        today_online_user_count: 0,
        this_week_online_user_count: 0,
        this_month_online_user_count: 0
    });

    const [contentStatistics, setContentStatistics] = useState({
        reading_count: 0,
        word_count: 0,
        wordset_count: 0,
        question_count: 0,
        course_count: 0,
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}user/getDashboardStats?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setUserStatistics({
                    total_registration_count: 0,
                    today_registration_count: 0,
                    this_week_registration_count: 0,
                    this_month_registration_count: 0,
                    today_online_user_count: 0,
                    this_week_online_user_count: 0,
                    this_month_online_user_count: 0
                });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUserStatistics(response.data.stats);
                }
            });

        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getContentDashboardStats?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setContentStatistics({
                    reading_count: 0,
                    word_count: 0,
                    wordset_count: 0,
                    question_count: 0,
                    course_count: 0,
                });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setContentStatistics(response.data.stats);
                }
            });
    }, []);

    return (
        <Box sx={{ p: "75px" }}>

            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Header title="Dashboard" subtitle="" />
            </Box>
            <Box>
                <Box>
                    <Typography variant="h4" fontWeight="bold" color={colors.grey[100]}>
                        Registration Statistics
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={"30px"}>
                        <StatisticBox
                            icon={<PeopleAltIcon sx={{ fontSize: "35px", color: colors.grey[200] }} />}
                            count={userStatistics.total_registration_count}
                            content="Total" />

                        <StatisticBox
                            icon={<PeopleAltIcon sx={{ fontSize: "35px", color: colors.grey[200] }} />}
                            count={userStatistics.this_month_registration_count}
                            content="This Month" />

                        <StatisticBox
                            icon={<PeopleAltIcon sx={{ fontSize: "35px", color: colors.grey[200] }} />}
                            count={userStatistics.this_week_registration_count}
                            content="This Week" />

                        <StatisticBox
                            icon={<PeopleAltIcon sx={{ fontSize: "35px", color: colors.grey[200] }} />}
                            count={userStatistics.today_registration_count}
                            content="Today" />
                    </Box>
                </Box>
                <Box mt={"50px"}>
                    <Typography variant="h4" fontWeight="bold" color={colors.grey[100]}>
                        Session Statistics
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={"30px"}>

                        <StatisticBox
                            icon={<LoginIcon sx={{ fontSize: "35px", color: colors.grey[200] }} />}
                            count={userStatistics.this_month_online_user_count}
                            content="This Month" />

                        <StatisticBox
                            icon={<LoginIcon sx={{ fontSize: "35px", color: colors.grey[200] }} />}
                            count={userStatistics.this_week_online_user_count}
                            content="This Week" />

                        <StatisticBox
                            icon={<LoginIcon sx={{ fontSize: "35px", color: colors.grey[200] }} />}
                            count={userStatistics.today_online_user_count}
                            content="Today" />
                    </Box>
                </Box>
                <Box mt={"50px"}>
                    <Typography variant="h4" fontWeight="bold" color={colors.grey[100]}>
                        Content Statistics
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={"30px"}>
                        <StatisticBox
                            icon={<ChromeReaderMode sx={{ fontSize: "35px", color: colors.grey[200] }} />}
                            count={contentStatistics.reading_count}
                            content="Readings" />

                        <StatisticBox
                            icon={<Translate sx={{ fontSize: "35px", color: colors.grey[200] }} />}
                            count={contentStatistics.word_count}
                            content="Words" />

                        <StatisticBox
                            icon={<Dataset sx={{ fontSize: "35px", color: colors.grey[200] }} />}
                            count={contentStatistics.wordset_count}
                            content="Word Sets" />

                        <StatisticBox
                            icon={<QuizOutlined sx={{ fontSize: "35px", color: colors.grey[200] }} />}
                            count={contentStatistics.question_count}
                            content="Questions" />

                        <StatisticBox
                            icon={<CastForEducation sx={{ fontSize: "35px", color: colors.grey[200] }} />}
                            count={contentStatistics.course_count}
                            content="Courses" />
                    </Box>
                </Box>


            </Box>
        </Box>
    )
}

function StatisticBox({ icon, count, content }) {
    const colors = tokens();
    return (
        <Box
            width="100%"
            mt="30px"
            p="25px"
            sx={{
                background: colors.primary[400],
                borderRadius: "20px",
                maxWidth: "250px",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)"
            }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    {icon}
                </Box>
                <Box textAlign="right" >
                    <Typography variant='h4' fontWeight="bold" sx={{ color: colors.grey[100] }}>
                        {count}
                    </Typography>
                    <Typography ml="10px" variant="h5" fontStyle="italic" sx={{ color: colors.greenAccent[200] }}>
                        {content}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default DashboardPage