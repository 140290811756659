import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

function ExportDialog({ dialogOpen, handleClose, handleFormSubmit }) {

    var initialValues = {
        fileExtention: "xlsx",
        contactPolicy: false,
        language: "all",
        proStatus: "any",
        minGold: 0
    };

    var validationObject = {
        fileExtention: yup.string().required("Field is required"),
        contactPolicy: yup.boolean().required("Field is required"),
        language: yup.string().required("Field is required"),
        proStatus: yup.string().required("Field is required"),
        minGold: yup.number().min(0, "Cant be less than 0").required("Field is required"),
    }

    const exportSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    Export Settings
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={exportSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" mb="25px">

                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="file-extention-label">File Extention</InputLabel>
                                        <Select
                                            labelId="file-extention-label"
                                            label="File Extention"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"fileExtention"}
                                            value={values.fileExtention}
                                            error={!!touched.fileExtention && !!errors.fileExtention}
                                        >
                                            <MenuItem value={"xlsx"}>xlsx</MenuItem>
                                            <MenuItem value={"csv"}>csv</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="policy-label">Contact Policy</InputLabel>
                                        <Select
                                            labelId="policy-label"
                                            label="Contact Policy"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"contactPolicy"}
                                            value={values.contactPolicy}
                                            error={!!touched.contactPolicy && !!errors.contactPolicy}
                                        >
                                            <MenuItem value={false}>All Users</MenuItem>
                                            <MenuItem value={true}>Only Approvers</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="language-label">Language</InputLabel>
                                        <Select
                                            labelId="language-label"
                                            label="Language"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"language"}
                                            value={values.language}
                                            error={!!touched.language && !!errors.language}
                                        >
                                            <MenuItem value={"all"}>All</MenuItem>
                                            <MenuItem value={"turkish"}>Turkish</MenuItem>
                                            <MenuItem value={"french"}>French</MenuItem>
                                            <MenuItem value={"german"}>German</MenuItem>
                                            <MenuItem value={"italian"}>Italian</MenuItem>
                                            <MenuItem value={"portuguese"}>Porteguese</MenuItem>
                                            <MenuItem value={"spanish"}>Spanish</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="pro-status-label">Pro Status</InputLabel>
                                        <Select
                                            labelId="pro-status-label"
                                            label="Pro Status"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"proStatus"}
                                            value={values.proStatus}
                                            error={!!touched.proStatus && !!errors.proStatus}
                                        >
                                            <MenuItem value={"any"}>All Users</MenuItem>
                                            <MenuItem value={"free"}>Free Users</MenuItem>
                                            <MenuItem value={"pro"}>Pro Users</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Minimum Gold"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"minGold"}
                                        value={values.minGold}
                                        error={!!touched.minGold && !!errors.minGold}
                                        helperText={touched.minGold && errors.minGold}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Export</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default ExportDialog