import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../../../theme'
import MoreVertIcon from '@mui/icons-material/MoreVert';

function ReadingBox({ reading, removeReading }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const colors = tokens();
    return (
        <Box mt={"30px"} >
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    window.location.href = `readings/edit/${reading.id}/details${reading.book_id ? "?chapter=true" : ""}`;
                }}>
                    Edit Reading
                </MenuItem>
                {!reading.coming_soon && <>
                    <MenuItem onClick={() => {
                        handleClose();
                        window.location.href = `readings/edit/${reading.id}/sentences`;
                    }}>
                        Edit Translations And Words
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleClose();
                        window.location.href = `readings/edit/${reading.id}/questions`;
                    }}>
                        Edit Questions
                    </MenuItem>
                </>}
                <MenuItem onClick={() => {
                    removeReading(reading);
                    handleClose()
                }}>
                    Remove
                </MenuItem>
            </Menu>

            <Box position="relative" bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Box position="absolute" top={15} right={15}>

                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>

                </Box>
                <Box>
                    <Box display="flex" alignItems="center">

                        <Typography variant='h3' fontWeight="600">
                            {reading.title}
                        </Typography>
                    </Box>
                    <Typography variant='h6' >
                        {`${reading.book_id ? reading.book_title : reading.difficulty === 2 ? "Hard" : (reading.difficulty === 1 ? "Medium" : "Easy")} - ${!!(reading.is_pro) ? "Pro" : "Free"}`}
                    </Typography>
                </Box>
                <Box component="hr" my="20px" border={"1px solid " + colors.grey[500]} />
                <Box display="flex" justifyContent="space-evenly" alignItems="center">
                    <Box display="flex" alignItems="center" gap="5px">
                        {reading.coming_soon ? "Coming Soon..." : reading.content}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ReadingBox