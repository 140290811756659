import React, { useContext } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../../../theme';
import { AdminContext } from '../../../../context/AdminContext';

function CourseCategoryDialog({ targetCourseCategory, dialogOpen, handleClose, onFormSubmited }) {

    const colors = tokens();
    const { token } = useContext(AdminContext);

    var initialValues = {
        title: targetCourseCategory?.title ? targetCourseCategory.title : "",
        translations: targetCourseCategory?.translations ? targetCourseCategory.translations.map((category) => ({ title_translation: category.title_translation, language: category.language })) : [{
            title_translation: "",
            language: "turkish"
        },
        {
            title_translation: "",
            language: "german"
        },
        {
            title_translation: "",
            language: "spanish"
        },
        {
            title_translation: "",
            language: "french"
        },
        {
            title_translation: "",
            language: "portuguese"
        },
        {
            title_translation: "",
            language: "italian"
        }]
    };

    var validationObject = {
        title: yup.string().required("Field is required"),
        translations: yup.array().of(
            yup.object({
                title_translation: yup.string().required("required"),
                language: yup.string().required("required")
            }).required("required")
        ).required("required")
    };

    const categorySchema = yup.object().shape(validationObject);

    const submitForm = async (values) => {

        if (targetCourseCategory) {
            //update

            await axios.patch(`${process.env.REACT_APP_API_URL}course/updateCourseCategoryWithTranslations?categoryId=${targetCourseCategory.id}&token=${token}`, {
                ...values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    onFormSubmited({ ...values, id: targetCourseCategory.id });
                }
            });

        } else {
            //add

            await axios.post(`${process.env.REACT_APP_API_URL}course/createCourseCategoryWithTranslations?token=${token}`, {
                ...values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    onFormSubmited();
                }
            });
        }

    }

    const performTranslate = (category, languageSymbol, cb) => {
        axios.patch(`${process.env.REACT_APP_API_URL}translateWithBing`, {
            sentence: category,
            languageSymbol: languageSymbol
        }).catch((err) => {
            console.log("err", err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                cb(response.data.translation);
            } else {
                cb("-");
            }
        })

    }

    const translateCategory = (category, translations, setFieldValue) => {
        performTranslate(category, "tr", (translation) => {
            var ix = translations.findIndex((t) => t.language === "turkish");
            setFieldValue(`translations[${ix}].title_translation`, translation)
        });
        performTranslate(category, "de", (translation) => {
            var ix = translations.findIndex((t) => t.language === "german");
            setFieldValue(`translations[${ix}].title_translation`, translation)
        });
        performTranslate(category, "es", (translation) => {
            var ix = translations.findIndex((t) => t.language === "spanish");
            setFieldValue(`translations[${ix}].title_translation`, translation)
        });
        performTranslate(category, "fr", (translation) => {
            var ix = translations.findIndex((t) => t.language === "french");
            setFieldValue(`translations[${ix}].title_translation`, translation)
        });
        performTranslate(category, "pt-PT", (translation) => {
            var ix = translations.findIndex((t) => t.language === "portuguese");
            setFieldValue(`translations[${ix}].title_translation`, translation)
        });
        performTranslate(category, "it", (translation) => {
            var ix = translations.findIndex((t) => t.language === "italian");
            setFieldValue(`translations[${ix}].title_translation`, translation)
        });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            fullWidth
            open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {targetCourseCategory ? "Edit Category" : "Add Category"}
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={submitForm}
                        initialValues={initialValues}
                        validationSchema={categorySchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Title"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <Button color="success" variant="contained" sx={{ gridColumn: "3 / span 2", marginTop: "20px" }} onClick={() => translateCategory(values.title, values.translations, setFieldValue)}>
                                        <Typography>Translate</Typography>
                                    </Button>

                                    {values.translations.map((translation, j) =>
                                        <TextField
                                            key={`translation-${j}`}
                                            variant="filled"
                                            type="text"
                                            label={`Word(${translation.language})`}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"translations[" + j + "].title_translation"}
                                            value={values.translations[j].title_translation}
                                            error={!!touched?.translations && !!touched?.translations[j]?.title_translation && !!errors?.translations && !!errors?.translations[j]?.title_translation}
                                            helperText={touched?.translations && touched?.translations[j]?.title_translation && errors?.translations && errors?.translations[j]?.title_translation}
                                            sx={{ gridColumn: "span 3" }}
                                        />
                                    )}

                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default CourseCategoryDialog