import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../global/Header'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { AdminContext } from '../../../context/AdminContext';
import { tokens } from '../../../theme';
import UserDialog from '../UserDialog';
import { DataGrid } from '@mui/x-data-grid';
import { CheckCircle } from '@mui/icons-material';
import UserCouponBox from '../../CampaingsPage/CouponsPage/UserCouponBox';


function UserDetailsPage() {
    const { userId } = useParams();
    const { token } = useContext(AdminContext);
    const colors = tokens();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userDialogOpen, setUserDialogOpen] = useState(false);



    const [logs, setLogs] = useState([]);
    const [isLogsLoading, setLogsLoading] = useState(true);
    const [logsOrderModel, setLogsOrderModel] = useState(null);
    const [logsRowCount, setLogsRowCount] = useState(0);
    const [logsPaginationModel, setLogsPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });


    const fetchUser = () => {
        axios.get(`${process.env.REACT_APP_API_URL}user/getUserViaId?id=${userId}`)
            .catch((err) => {
                console.log("err: " + err);
                setUser(null);
                setLoading(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUser(response.data.user);
                    setLoading(false);
                }
            });
    };

    const fetchLogs = () => {
        setLogsLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}user/getUserLogs?userId=${userId}&startIndex=${logsPaginationModel.pageSize * logsPaginationModel.page}&limit=${logsPaginationModel.pageSize}${logsOrderModel ? `&orderColumn=${logsOrderModel.field}&orderType=${logsOrderModel.sort}` : ""}&token=${token}`)
            .catch((err) => {
                setLogs([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setLogs(response.data.logs);
                    setLogsRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLogsLoading(false);
            });
    };

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        fetchLogs();
    }, [logsPaginationModel, logsOrderModel]);


    const updateUser = async (user) => {

        if (user.photo) {
            await axios.post(`${process.env.REACT_APP_API_URL}user/uploadProfilePhoto`, {
                profilePhoto: user.photo
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        delete user.photo;
                        delete user.profile_photo_link;
                        user.profile_photo_path = response.data.photoPath;
                    }
                });
        }



        var userToUpdate = { ...user };
        delete userToUpdate.photo;
        delete userToUpdate.profile_photo_link;

        await axios.patch(`${process.env.REACT_APP_API_URL}user/updateUser?userId=${userId}`, {
            user: userToUpdate
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.isUpdated === true && response.data.status === 201) {
                    setUser(prev => ({ ...prev, ...userToUpdate }));
                    if (userDialogOpen) {
                        setUserDialogOpen(false);
                    }
                }
            });
    }


    if (loading && !user) {
        return (
            <Box sx={{ p: "75px", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <CircularProgress color="primary" />
            </Box>
        )
    }


    return (
        <Box sx={{ p: "75px" }}>
            <UserDialog dialogOpen={userDialogOpen} handleClose={() => setUserDialogOpen(false)} targetUser={user} handleFormSubmit={(values) => updateUser(values)} />

            <Box display="flex" alignItems="center" gap="20px">
                <Header title={user?.name} subtitle="" />
                <Button variant='contained' color='success' sx={{ mb: "30px", width: '25ch' }} onClick={() => {
                    setUserDialogOpen(true);
                }} >
                    Edit User
                </Button>
            </Box>

            <Typography variant='h5' color="primary">
                {user?.bio}
            </Typography>

            <Box mt="40px">
                <Box >
                    <Box sx={{ p: 1, border: "1px solid", borderColor: "grey.300", borderRadius: "5px", maxHeight: "300px", ...(user?.courses?.length > 0 ? { minHeight: "300px" } : {}), overflowY: "scroll" }}>
                        <Box display="flex" alignItems="center" gap="10px">
                            <Typography variant="h6" color="primary" fontWeight="bold">
                                Enrolled Courses
                            </Typography>
                        </Box>
                        <Box mt="30px">
                            {
                                user?.courses?.length > 0 ?
                                    <Box display="flex" flexWrap={"wrap"} gap={"10px"}>
                                        {user?.courses?.map((course, index) => (
                                            //thumbnail, title, completed_date
                                            <Box key={index} display="flex" alignItems="center" justifyContent="space-between" sx={{ p: 1, border: "1px solid", borderColor: "grey.500", borderRadius: "5px", mt: "10px", maxWidth: "400px", minWidth: "400px" }}>
                                                <Box display="flex" gap="10px">
                                                    <img src={course.thumbnail_link} alt={course.title} width="130px" height="150px" style={{
                                                        objectFit: "cover",
                                                    }} />
                                                    <Box display="flex" flexDirection="column" gap="10px">
                                                        <Typography variant="h3" color="primary">
                                                            {course.title}
                                                        </Typography>
                                                        <Typography variant="body1" color="primary">
                                                            Enrolled Date:<br />
                                                            {new Date(course.enrolled_date).toLocaleString()}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                    :
                                    <Typography variant="body1" color="primary">
                                        User has not enrolled any course yet.
                                    </Typography>
                            }
                        </Box>
                    </Box>
                </Box>
                <Box mt="20px" >
                    <Box sx={{ p: 1, border: "1px solid", borderColor: "grey.300", borderRadius: "5px", maxHeight: "300px", ...(user?.readings?.length > 0 ? { minHeight: "300px" } : {}), overflowY: "scroll" }}>
                        <Box display="flex" alignItems="center" gap="10px">
                            <Typography variant="h6" color="primary" fontWeight="bold">
                                Completed Readings
                            </Typography>
                        </Box>
                        <Box mt="30px">
                            {
                                user?.readings?.length > 0 ?
                                    <Box display="flex" flexWrap={"wrap"} gap={"10px"}>
                                        {
                                            user?.readings?.map((reading, index) => (
                                                //thumbnail, title, completed_date, status(1 yellow tick, 2 green tick)
                                                <Box key={index} position="relative" display="flex" alignItems="center" justifyContent="space-between" sx={{ p: 1, border: "1px solid", borderColor: "grey.500", borderRadius: "5px", mt: "10px", maxWidth: "400px", minWidth: "400px" }}>
                                                    <Box display="flex" gap="10px">
                                                        <img src={reading.thumbnail_link} alt={reading.title} width="130px" height="150px" style={{
                                                            objectFit: "cover",
                                                        }} />
                                                        <Box display="flex" flexDirection="column" gap="10px">
                                                            <Typography variant="h3" color="primary">
                                                                {reading.title}
                                                            </Typography>
                                                            <Typography variant="body1" color="primary">
                                                                Completed Date:<br />
                                                                {new Date(reading.completed_date).toLocaleString()}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box position="absolute" bottom="0" right="0" sx={{ mr: "10px" }}>
                                                        <CheckCircle sx={{ color: reading.status === 1 ? "#ffbc00" : "#2d8000", fontSize: '30px' }} />
                                                    </Box>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                    :
                                    <Typography variant="body1" color="primary">
                                        User has not completed any reading yet.
                                    </Typography>
                            }
                        </Box>
                    </Box>
                </Box>
                <Box mt="20px" >
                    <Box sx={{ p: 1, border: "1px solid", borderColor: "grey.300", borderRadius: "5px", maxHeight: "300px", ...(user?.coupons?.length > 0 ? { minHeight: "300px" } : {}), overflowY: "scroll" }}>
                        <Box display="flex" alignItems="center" gap="10px">
                            <Typography variant="h6" color="primary" fontWeight="bold">
                                Used Coupons
                            </Typography>
                        </Box>
                        <Box>
                            {
                                user?.coupons?.length > 0 ?
                                    <Box display="flex" flexWrap={"wrap"} gap={"10px"}>
                                        {
                                            user?.coupons?.map((coupon, index) => (
                                                <UserCouponBox key={coupon.id} coupon={coupon} />
                                            ))
                                        }
                                    </Box>
                                    :
                                    <Typography variant="body1" color="primary" mt="30px">
                                        User has not used any coupon yet.
                                    </Typography>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box mt="40px">
                <Typography variant="h5" color="primary" fontWeight="bold">
                    Logs
                </Typography>
                {
                    logs?.length > 0 ?
                        <Box mt="20px">
                            <DataGrid
                                rows={logs}
                                columns={[
                                    { field: 'id', headerName: 'ID', width: 90 },
                                    {
                                        field: 'action', headerName: 'action', width: 300,
                                        renderCell: (params) => (
                                            <Typography variant="body1" color="primary">
                                                {
                                                    params.row.action === "login" && "User logged in."
                                                }
                                                {
                                                    params.row.action === "reading_read" && "User completed a reading without quiz."
                                                }
                                                {
                                                    params.row.action === "reading_quiz_done" && "User completed a reading quiz."
                                                }
                                                {
                                                    params.row.action === "course_enroll" && "User enrolled a course."
                                                }
                                                {
                                                    params.row.action === "course_saved" && "User saved a course."
                                                }
                                                {
                                                    params.row.action === "course_unsaved" && "User unsaved a course."
                                                }
                                                {
                                                    params.row.action === "course_rate" && "User rated a course."
                                                }
                                                {
                                                    params.row.action === "coupon_used" && "User used a coupon."
                                                }
                                                {
                                                    //TODO: add request to related script
                                                    params.row.action === "gold_earning_from_scoreboard" && "User earned gold from scoreboard."
                                                }
                                                {
                                                    //TODO: add request after update loyalty system
                                                    params.row.action === "gold_earning_from_daily_login" && "User earned gold from daily login."
                                                }
                                                {
                                                    params.row.action === "gold_earning_from_watching_ad" && "User earned gold from watching ad."
                                                }
                                                {
                                                    params.row.action === "created_word_set" && "User created a word set."
                                                }
                                                {
                                                    params.row.action === "studied_word_set" && "User studied a word set."
                                                }
                                                {
                                                    params.row.action === "buy_gold" && "User bought gold."
                                                }
                                                {
                                                    params.row.action === "buy_premium" && "User bought premium."
                                                }
                                                {
                                                    params.row.action === "buy_heart" && "User bought heart."
                                                }
                                                {
                                                    params.row.action === "heart_earning_from_watching_ad" && "User earned heart from watching ad."
                                                }
                                            </Typography>
                                        ),
                                    },
                                    {
                                        field: 'created_date', headerName: 'Created Date', width: 200,
                                        renderCell: (params) => (
                                            <Typography variant="body1" color="primary">
                                                {new Date(params.row.created_date).toLocaleString()}
                                            </Typography>
                                        ),
                                    },
                                ]}
                                rowCount={logsRowCount}
                                loading={isLogsLoading}
                                pageSizeOptions={[10, 25, 50, 75, 100]}
                                paginationModel={logsPaginationModel}
                                paginationMode="server"
                                onPaginationModelChange={setLogsPaginationModel}
                                sortingMode='server'
                                onSortModelChange={(model) => {
                                    setLogsOrderModel((prev) => model && model.length > 0 ? model[0] : null);
                                    setLogsPaginationModel(prev => { return { ...prev, page: 0 } });
                                }}
                                disableColumnFilter
                                sx={{
                                    ".MuiDataGrid-columnHeaders": {
                                        background: colors.grey[900]
                                    }
                                }}
                            />
                        </Box>
                        :
                        < Box mt="20px">
                            <Typography variant="body1" color="primary">
                                There are no logs for this user yet.
                            </Typography>
                        </Box>
                }
            </Box>
        </Box >
    )
}

export default UserDetailsPage