import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Tooltip, Typography } from '@mui/material'
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import React, { useContext, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { tokens } from '../../../../../../../theme';
import { AdminContext } from '../../../../../../../context/AdminContext';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonIcon from '@mui/icons-material/Person';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import TranslateIcon from '@mui/icons-material/Translate';


function SelectDialog({ type, onSubmitted, dialogOpen, handleClose }) {
    const { token } = useContext(AdminContext);
    const colors = tokens();
    const [elements, setElements] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);

    const fetchElements = async () => {
        if (type === 2) {
            axios.get(`${process.env.REACT_APP_API_URL}wordset/getWordSets?${searchKey ? `searchKey=${searchKey}` : ""}&startIndex=${startIndex}&limit=20`)
                .catch((err) => {
                    console.log("err: " + err);
                    if (startIndex === 0) {
                        setElements([]);
                    }
                    setHasMore(false);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 200) {
                        setHasMore(response.data.wordSets && response.data.wordSets.length === 20);
                        if (startIndex === 0) {
                            setElements(response.data.wordSets);
                        } else {
                            setElements((prev) => [...prev, ...response.data.wordSets]);
                        }
                    }
                });
        } else if (type === 3) {
            axios.get(`${process.env.REACT_APP_API_URL}reading/getReadings?${searchKey ? `searchKey=${searchKey}` : ""}&startIndex=${startIndex}&limit=20`)
                .catch((err) => {
                    console.log("err: " + err);
                    if (startIndex === 0) {
                        setElements([]);
                    }
                    setHasMore(false);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 200) {
                        setHasMore(response.data.readings && response.data.readings.length === 20);
                        if (startIndex === 0) {
                            setElements(response.data.readings);
                        } else {
                            setElements((prev) => [...prev, ...response.data.readings]);
                        }
                    }
                });
        }
    }

    useEffect(() => {
        fetchElements();
    }, [startIndex]);

    return (
        <Dialog
            open={dialogOpen}
            onClose={
                () => {
                    setSearchKey("");
                    setElements([]);
                    setHasMore(false);
                    setStartIndex(0);
                    handleClose();
                }
            }
            fullWidth
            maxWidth="md"
        >
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="10px">
                    <DialogTitle variant='h2' >
                        {type === 3 ? "Readings" : "Wordsets"}
                    </DialogTitle>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Search</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='text'
                            onChange={(e) => {
                                setSearchKey(e.target.value ? e.target.value : "");
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="search button"
                                        onClick={() => {
                                            setElements([]);
                                            if (startIndex === 0) {
                                                fetchElements();
                                            } else {
                                                setStartIndex(0);
                                            }
                                        }}
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Search"
                        />
                    </FormControl>
                </Box>
                <Button
                    variant='outlined'
                    color='error'
                    sx={{ minWidth: "30px", width: "30px", height: "30px", m: 1, borderRadius: "50%" }}
                    onClick={() => {
                        setSearchKey("");
                        setElements([]);
                        setHasMore(false);
                        setStartIndex(0);
                        handleClose();
                    }}>
                    <Typography variant="button" color="error" fontSize={15}>
                        X
                    </Typography>
                </Button>
            </Box>

            <DialogContent dividers>
                <InfiniteScroll
                    dataLength={elements.length}
                    next={() => {
                        setStartIndex(elements.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>You are viewing all {type === 3 ? "readings" : "wordsets"}.</b>
                        </p>
                    }
                >
                    <Box>
                        {elements.map(element => {
                            return (type === 3 ?
                                <Box key={element.id} mt={"30px"} >
                                    <Box>
                                        <Box display="flex" alignItems="center" justifyContent={"space-between"}>
                                            <Typography variant='h3' fontWeight="600">
                                                {element.title}
                                            </Typography>
                                            <Button variant='contained' color='success' sx={{ m: 1, width: '10ch' }} onClick={
                                                () => {
                                                    onSubmitted({ ...element });
                                                    handleClose();
                                                }
                                            } >
                                                <Typography variant="button" color="white" fontSize={10}>
                                                    Select
                                                </Typography>
                                            </Button>
                                        </Box>
                                        <Typography variant='h6' >
                                            {`${element.difficulty === 2 ? "Hard" : (element.difficulty === 1 ? "Medium" : "Easy")} - ${!!(element.is_pro) ? "Pro" : "Free"}`}
                                        </Typography>
                                    </Box>
                                    <Box component="hr" my="20px" border={"1px solid " + colors.grey[500]} />
                                    <Typography variant='body1' maxHeight={"60px"} overflow={"hidden"} sx={{ overflowX: "ellipsis" }}>
                                        {element.content}
                                    </Typography>
                                </Box>
                                :
                                <Box key={element.id} position="relative" mt={"30px"} bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                                    <Box position="absolute" top={15} right={15}>

                                        <Button variant='contained' color='success' sx={{ m: 1, width: '10ch' }} onClick={
                                            () => {
                                                onSubmitted({ ...element });
                                                handleClose();
                                            }
                                        } >
                                            <Typography variant="button" color="white" fontSize={10}>
                                                Select
                                            </Typography>
                                        </Button>

                                    </Box>
                                    <Box>
                                        <Box display="flex" alignItems="center">

                                            <Typography variant='h3' fontWeight="600">
                                                {element.name}
                                            </Typography>
                                            <Tooltip title={element.language[0].toUpperCase() + element.language.slice(1)} ml="10px">
                                                <Box
                                                    component="img"
                                                    src={element.language === "turkish" ? './flags/turkey.png' : (element.language === "german" ? './flags/germany.png' : (element.language === "italian" ? './flags/italy.png' : (element.language === "spanish" ? './flags/spain.png' : './flags/portugal.png')))}
                                                />
                                            </Tooltip>
                                        </Box>
                                        <Typography variant='h6' >
                                            {element.privacy === 2 ? "Private" : (element.privacy === 1 ? "Followers Only" : "Public")}
                                        </Typography>

                                        <Box display="flex" alignItems="center" gap="10px" mt="10px">
                                            <img src={element.profile_photo_link} alt='profile-pic' style={{ height: "30px", width: "30px" }} />
                                            <Typography variant='h4'>
                                                {element.username}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box component="hr" my="20px" border={"1px solid " + colors.grey[500]} />
                                    <Box display="flex" justifyContent="space-evenly" alignItems="center">
                                        <Box display="flex" alignItems="center" gap="5px">
                                            <FavoriteIcon />
                                            <Typography variant='h4'>
                                                {element.like_count}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" gap="5px">
                                            <PersonIcon />
                                            <Typography variant='h4'>
                                                {element.follower_count}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" gap="5px">
                                            <AutoStoriesIcon />
                                            <Typography variant='h4'>
                                                {element.study_count}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" gap="5px">
                                            <TranslateIcon />
                                            <Typography variant='h4'>
                                                {element.word_count}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>)
                        }
                        )}
                    </Box>
                </InfiniteScroll>
            </DialogContent>

        </Dialog>
    )
}

export default SelectDialog