import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, OutlinedInput } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { AdminContext } from '../../../context/AdminContext';
import CourseCategoryDialog from './CourseCategoryDialog';
import { tokens } from '../../../theme';
import Header from '../../../global/Header';

function CourseCategoriesPage() {
    const colors = tokens();
    const { token } = useContext(AdminContext);
    const [isLoading, setLoading] = useState(true);
    const [searchKey, setSearchKey] = useState("");
    const [orderModel, setOrderModel] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [courseCategories, setCourseCategories] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [courseCategoryDialogOpen, setCourseCategoryDialogOpen] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    const [targetCourseCategory, setTargetCourseCategory] = useState(null);
    const [targetCourseCategoryToRemove, setTargetCourseCategoryToRemove] = useState(null);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        {
            field: 'title',
            headerName: 'TITLE',
            flex: 1
        }, {
            field: 'course_count',
            headerName: 'COURSE COUNT',
            flex: 1
        }, {
            field: "created_date", headerName: "CREATED AT",
            renderCell: ({ row: { created_date } }) => {
                return new Date(created_date).toLocaleDateString()
            }
        }, {
            field: 'actions',
            type: 'actions',
            sortable: false,
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit Category"
                    onClick={() => setTargetCourseCategory(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<InfoIcon />}
                    label="Remove Category"
                    onClick={() => setTargetCourseCategoryToRemove(params.row)}
                    showInMenu
                />
            ],
        }
    ];

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}course/getCourseCategories?startIndex=${paginationModel.pageSize * paginationModel.page}&limit=${paginationModel.pageSize}${searchKey ? `&searchKey=${searchKey}` : ""}${orderModel ? `&orderColumn=${orderModel.field}&orderType=${orderModel.sort}` : ""}`)
            .catch((err) => {
                setCourseCategories([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCourseCategories(response.data.categories);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }, [paginationModel, orderModel]);

    useEffect(() => {
        if (targetCourseCategory) {
            setCourseCategoryDialogOpen(true);
        }
    }, [targetCourseCategory]);

    const handleRemoveDialogClose = async () => {
        setTargetCourseCategoryToRemove(null);
        setRemoveDialogOpen(false);
    }

    const removeCourseCategory = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}course/deleteCourseCategory?categoryId=${targetCourseCategoryToRemove.id}&token=${token}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setPaginationModel(prev => ({ ...prev }));
                handleRemoveDialogClose();
            }
        });
    }

    useEffect(() => {
        if (targetCourseCategoryToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [targetCourseCategoryToRemove])

    return (
        <Box sx={{ p: "75px" }}>
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Are you sure you want to delete the category?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot undo this operation but you can add new category if you wish. Some of the courses may remain without a category.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                    <Button onClick={() => removeCourseCategory()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <CourseCategoryDialog
                targetCourseCategory={targetCourseCategory}
                dialogOpen={courseCategoryDialogOpen}
                handleClose={() => {
                    setCourseCategoryDialogOpen(false)
                    setTargetCourseCategory(null);
                }}
                onFormSubmited={() => {
                    setCourseCategoryDialogOpen(false);
                    setTargetCourseCategory(null);
                    setPaginationModel(prev => { return { ...prev, page: 0 } })
                }} />

            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Course Categories" subtitle="You can see and manage course categories from this page." />
                    <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                        setCourseCategoryDialogOpen(true);
                    }} >
                        Add New Category
                    </Button>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Search</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='text'
                            onChange={(e) => {
                                setSearchKey(e.target.value ? e.target.value : "");
                            }}
                            label="Search"
                        />
                    </FormControl>
                    <IconButton
                        aria-label="search button"
                        sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px" }}
                        onClick={() => { setPaginationModel(prev => { return { ...prev, page: 0 } }) }}
                        edge="end"
                    >
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
            <DataGrid
                rows={courseCategories}
                columns={columns}
                rowCount={rowCount}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 75, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode='server'
                onSortModelChange={(model) => {
                    setOrderModel((prev) => model && model.length > 0 ? model[0] : null);
                    setPaginationModel(prev => { return { ...prev, page: 0 } });
                }}
                disableColumnFilter
                sx={{
                    ".MuiDataGrid-columnHeaders": {
                        background: colors.grey[900]
                    }
                }}
            />
        </Box>
    )
}

export default CourseCategoriesPage