import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../../theme';

function MakeInstructorDialog({ targetUser, dialogOpen, handleClose, handleFormSubmit }) {

    const colors = tokens();

    var initialValues = {
        instructor_title: targetUser?.instructor_title ? targetUser.instructor_title : "",
        instructor_bio: targetUser?.instructor_bio ? targetUser.instructor_bio : "",
        instructor_translations: targetUser?.instructor_translations?.length > 0 ? targetUser.instructor_translations : [{
            title_translation: "",
            bio_translation: "",
            language: "turkish"
        },
        {
            title_translation: "",
            bio_translation: "",
            language: "german"
        },
        {
            title_translation: "",
            bio_translation: "",
            language: "spanish"
        },
        {
            title_translation: "",
            bio_translation: "",
            language: "french"
        },
        {
            title_translation: "",
            bio_translation: "",
            language: "portuguese"
        },
        {
            title_translation: "",
            bio_translation: "",
            language: "italian"
        }],
    };

    var validationObject = {
        instructor_title: yup.string().required("Field is required"),
        instructor_bio: yup.string().required("Field is required"),
        instructor_translations: yup.array().of(
            yup.object({
                title_translation: yup.string().required("required"),
                bio_translation: yup.string().required("required"),
                language: yup.string().required("required")
            }).required("required")
        ).required("required")
    }

    const instructorSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={dialogOpen}
            fullWidth
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {targetUser?.is_instructor === 1 ? "Edit Instructor" : "Make Instructor"}
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={(values) => {
                            handleFormSubmit({
                                is_instructor: 1,
                                ...values
                            });
                        }}
                        initialValues={initialValues}
                        validationSchema={instructorSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Title"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"instructor_title"}
                                        value={values.instructor_title}
                                        error={!!touched.instructor_title && !!errors.instructor_title}
                                        helperText={touched.instructor_title && errors.instructor_title}
                                        sx={{ gridColumn: "span 2", marginTop: "10px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        multiline
                                        rows={4}
                                        label="Bio"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"instructor_bio"}
                                        value={values.instructor_bio}
                                        error={!!touched.instructor_bio && !!errors.instructor_bio}
                                        helperText={touched.instructor_bio && errors.instructor_bio}
                                        sx={{ gridColumn: "span 3" }} />

                                    {values.instructor_translations.map((translation, j) =>
                                        <>
                                            <TextField
                                                key={`title-translation-${j}`}
                                                variant="filled"
                                                type="text"
                                                label={`Title(${translation.language})`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"instructor_translations[" + j + "].title_translation"}
                                                value={values.instructor_translations[j].title_translation}
                                                error={!!touched?.instructor_translations && !!touched?.instructor_translations[j]?.title_translation && !!errors?.instructor_translations && !!errors?.instructor_translations[j]?.title_translation}
                                                helperText={touched?.instructor_translations && touched?.instructor_translations[j]?.title_translation && errors?.instructor_translations && errors?.instructor_translations[j]?.title_translation}
                                                sx={{ gridColumn: "span 2", marginTop: "10px" }}
                                            />
                                            <TextField
                                                key={`bio-translation-${j}`}
                                                variant="filled"
                                                type="text"
                                                multiline
                                                rows={4}
                                                label={`Bio(${translation.language})`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"instructor_translations[" + j + "].bio_translation"}
                                                value={values.instructor_translations[j].bio_translation}
                                                error={!!touched?.instructor_translations && !!touched?.instructor_translations[j]?.bio_translation && !!errors?.instructor_translations && !!errors?.instructor_translations[j]?.bio_translation}
                                                helperText={touched?.instructor_translations && touched?.instructor_translations[j]?.bio_translation && errors?.instructor_translations && errors?.instructor_translations[j]?.bio_translation}
                                                sx={{ gridColumn: "span 3" }}
                                            />
                                        </>
                                    )}
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default MakeInstructorDialog