import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useContext } from 'react'
import { AdminContext } from '../../../context/AdminContext';
import axios from 'axios';

function CourseRemoveDialog({ courseId, removeDialogOpen, handleRemoveDialogClose, onCourseRemoved }) {
    const { token } = useContext(AdminContext);

    const removeCourse = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}course/deleteCourse?courseId=${courseId}&token=${token}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                onCourseRemoved();
            }
        });
    }

    return (
        <Dialog
            open={removeDialogOpen}
            keepMounted
            onClose={handleRemoveDialogClose}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{"Are you sure you want to delete the course?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You cannot undo this operation but you can add new course if you wish.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRemoveDialogClose}>Cancel</Button>
                <Button onClick={() => removeCourse()}>Delete</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CourseRemoveDialog