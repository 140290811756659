import React, { useContext, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../../../../theme';
import { AdminContext } from '../../../../../context/AdminContext';

function LessonDialog({ targetLesson, courseId, sectionId, nextPosition, dialogOpen, handleClose, onFormSubmited }) {

    const colors = tokens();
    const { token } = useContext(AdminContext);
    const [removedQuestions, setRemovedQuestions] = useState([]);

    const [isUploading, setUploading] = useState(false);

    var initialValues = {
        title: targetLesson?.title ? targetLesson.title : "",
        type: targetLesson?.type !== undefined && targetLesson.type !== null ? targetLesson.type : "",
        previewable: targetLesson?.previewable ? targetLesson.previewable === 1 : false,
        video: null,
        video_link: targetLesson?.video_link ? targetLesson.video_link : "",
        pdf: null,
        pdf_link: targetLesson?.pdf_link ? targetLesson.pdf_link : "",
        pdf_name: targetLesson?.pdf_name ? targetLesson.pdf_name : "",
        questions: targetLesson?.questions ? targetLesson.questions : [
            {
                question: "",
                a: "",
                a_tip: "",
                b: "",
                b_tip: "",
                c: "",
                c_tip: "",
                d: "",
                d_tip: "",
                answer: ""
            }
        ]
    };

    var validationObject = {
        title: yup.string().required("Field is required"),
        type: yup.number().required("Field is required"),
        previewable: yup.boolean(),
        // video is required if type is 0 and video_link is empty
        video: yup.mixed().nullable().when(['video_link', 'type'], {
            is: (video_link, type) => (!video_link || video_link === "") && type === 0,
            then: () => yup.mixed().nonNullable("").required('Field is required')
        }),
        video_link: yup.string(),
        // pdf is required if type is 1 and pdf_link is empty
        pdf: yup.mixed().nullable().when(['pdf_link', 'type'], {
            is: (pdf_link, type) => (!pdf_link || pdf_link === "") && type === 1,
            then: () => yup.mixed().nonNullable("").required('Field is required')
        }),
        pdf_link: yup.string(),
        pdf_name: yup.string().when('type', {
            is: (type) => type === 1,
            then: () => yup.string().required("Field is required")
        }),
        questions: yup.array().when('type', {
            is: (type) => type === 2,
            then: () => yup.array().of(
                yup.object().shape({
                    id: yup.number().nullable(),
                    question: yup.string().required("Field is required"),
                    a: yup.string().required("Field is required"),
                    a_tip: yup.string(),
                    b: yup.string().required("Field is required"),
                    b_tip: yup.string(),
                    c: yup.string(),
                    c_tip: yup.string(),
                    d: yup.string(),
                    d_tip: yup.string(),
                    answer: yup.string().required("Field is required")
                })
            ).min(1, "At least one question is required")
        })
    };

    const lessonSchema = yup.object().shape(validationObject);

    const submitForm = async (values) => {
        setUploading(true);
        if (values.video) {
            values.video_length_in_seconds = 0;
            //calculate video duration in seconds
            let video = document.createElement('video');
            video.src = URL.createObjectURL(values.video);
            video.onloadedmetadata = function () {
                values.video_length_in_seconds = parseInt(video.duration);
            }
        }

        if (values.video || values.pdf) {
            await axios.post(`${process.env.REACT_APP_API_URL}course/uploadLessonContent?token=${token}`, {
                lessonContent: values.video || values.pdf
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        if (values.video) {
                            values.video_path = response.data.lessonContentPath;
                        } else {
                            values.pdf_path = response.data.lessonContentPath;
                        }

                    }
                });
        }

        let lesson = { ...values };
        delete lesson.video;
        delete lesson.video_link;
        delete lesson.pdf;
        delete lesson.pdf_link;
        delete lesson.questions;

        console.log(lesson);

        if (targetLesson) {
            //update

            let updatedQuestions = []
            let newQuestions = []

            if (lesson.type === 2) {
                updatedQuestions = values.questions.map((question) => {
                    if (question.id) {
                        let questionToCompare = targetLesson.questions.find((q) => q.id === question.id);
                        if (!(questionToCompare.question === question.question && questionToCompare.a === question.a && questionToCompare.b === question.b && questionToCompare.c === question.c && questionToCompare.d === question.d && questionToCompare.a_tip === question.a_tip && questionToCompare.b_tip === question.b_tip && questionToCompare.c_tip === question.c_tip && questionToCompare.d_tip === question.d_tip && questionToCompare.answer === question.answer)) {
                            return question;
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                }).filter((question) => question !== null);

                newQuestions = values.questions.filter((question) => !question.id);
            }

            await axios.patch(`${process.env.REACT_APP_API_URL}course/updateLesson?lessonId=${targetLesson.id}&token=${token}`, {
                lesson: lesson,
                questionsToRemove: removedQuestions,
                questionsToUpdate: updatedQuestions,
                questionsToAdd: newQuestions
            }).catch((err) => {
                console.log("err: " + err);
                setUploading(false);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setUploading(false);
                    onFormSubmited();
                }
            });

        } else {
            //add

            await axios.post(`${process.env.REACT_APP_API_URL}course/createLesson?token=${token}`, {
                lesson: { ...lesson, course_id: courseId, section_id: sectionId, position: nextPosition },
                questions: values.questions || []
            }).catch((err) => {
                console.log("err: " + err);
                setUploading(false);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setUploading(false);
                    onFormSubmited();
                }
            });
        }

    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            fullWidth
            maxWidth="md"
            open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {targetLesson ? "Edit Lesson" : "Add Lesson"}
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={submitForm}
                        initialValues={initialValues}
                        validationSchema={lessonSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns={targetLesson ? "repeat(3, minmax(0, 1fr))" : "repeat(4, minmax(0, 1fr))"} mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Title"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 2" }}
                                    />

                                    {
                                        !targetLesson &&
                                        <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                            <InputLabel id="filter-label">Type</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                label="Type"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"type"}
                                                value={values.type}
                                                error={!!touched.type && !!errors.type}
                                            >
                                                <MenuItem value={0}>Video</MenuItem>
                                                <MenuItem value={1}>PDF</MenuItem>
                                                <MenuItem value={2}>Quiz</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.previewable}
                                                onChange={handleChange}
                                                name="previewable"
                                                color="primary"
                                            />
                                        }
                                        label="Previewable"
                                        sx={{ gridColumn: "span 1", mx: "auto" }}
                                    />

                                    {
                                        values.type === 0 &&
                                        <>
                                            <Typography variant='h6' sx={{ gridColumn: "span 4", marginTop: "20px" }}>Upload Video</Typography>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                type="file"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    setFieldValue("video", e.target.files[0]);
                                                }}
                                                name={"video"}
                                                error={!!touched.video && !!errors.video}
                                                helperText={touched.video && errors.video}
                                                inputProps={{ accept: ".mp4" }}
                                                sx={{ gridColumn: "span 4" }}
                                            />
                                        </>
                                    }

                                    {
                                        values.type === 1 &&
                                        <>
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label="PDF Name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"pdf_name"}
                                                value={values.pdf_name}
                                                error={!!touched.pdf_name && !!errors.pdf_name}
                                                helperText={touched.pdf_name && errors.pdf_name}
                                                sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                            />
                                            <Box sx={{ gridColumn: "span 4", marginTop: "10px" }}>
                                                <Typography variant='h6'>Upload PDF</Typography>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    type="file"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        setFieldValue("pdf", e.target.files[0]);
                                                    }}
                                                    name={"pdf"}
                                                    error={!!touched.pdf && !!errors.pdf}
                                                    helperText={touched.pdf && errors.pdf}
                                                    //only accept pdf files
                                                    inputProps={{ accept: ".pdf" }}
                                                />
                                            </Box>
                                        </>
                                    }

                                    {
                                        values.type === 2 &&
                                        <Box sx={{ gridColumn: "span 4" }}>
                                            {
                                                values.questions.map((question, index) => (
                                                    <Box key={index} display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr)">
                                                        <TextField
                                                            fullWidth
                                                            variant="filled"
                                                            type="text"
                                                            label="Question"
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                setFieldValue(`questions[${index}].question`, e.target.value);
                                                            }}
                                                            name={`questions[${index}].question`}
                                                            value={question.question}
                                                            error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].question}
                                                            helperText={touched.questions && errors.questions && errors.questions[index] && errors.questions[index].question}
                                                            sx={{ gridColumn: "span 4" }}
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            variant="filled"
                                                            type="text"
                                                            label="A"
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                setFieldValue(`questions[${index}].a`, e.target.value);
                                                            }}
                                                            name={`questions[${index}].a`}
                                                            value={question.a}
                                                            error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].a}
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            variant="filled"
                                                            type="text"
                                                            label="B"
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                setFieldValue(`questions[${index}].b`, e.target.value);
                                                            }}
                                                            name={`questions[${index}].b`}
                                                            value={question.b}
                                                            error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].b}
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            variant="filled"
                                                            type="text"
                                                            label="C"
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                setFieldValue(`questions[${index}].c`, e.target.value);
                                                            }}
                                                            name={`questions[${index}].c`}
                                                            value={question.c}
                                                            error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].c}
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            variant="filled"
                                                            type="text"
                                                            label="D"
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                setFieldValue(`questions[${index}].d`, e.target.value);
                                                            }}
                                                            name={`questions[${index}].d`}
                                                            value={question.d}
                                                            error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].d}
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            variant="filled"
                                                            type="text"
                                                            label="A Tip"
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                setFieldValue(`questions[${index}].a_tip`, e.target.value);
                                                            }}
                                                            name={`questions[${index}].a_tip`}
                                                            value={question.a_tip}
                                                            error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].a_tip}
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            variant="filled"
                                                            type="text"
                                                            label="B Tip"
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                setFieldValue(`questions[${index}].b_tip`, e.target.value);
                                                            }}
                                                            name={`questions[${index}].b_tip`}
                                                            value={question.b_tip}
                                                            error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].b_tip}
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            variant="filled"
                                                            type="text"
                                                            label="C Tip"
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                setFieldValue(`questions[${index}].c_tip`, e.target.value);
                                                            }}
                                                            name={`questions[${index}].c_tip`}
                                                            value={question.c_tip}
                                                            error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].c_tip}
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            variant="filled"
                                                            type="text"
                                                            label="D Tip"
                                                            onBlur={handleBlur}
                                                            onChange={(e) => {
                                                                setFieldValue(`questions[${index}].d_tip`, e.target.value);
                                                            }}
                                                            name={`questions[${index}].d_tip`}
                                                            value={question.d_tip}
                                                            error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].d_tip}
                                                        />
                                                        <FormControl fullWidth variant="filled">
                                                            <InputLabel id="answer-label">Answer</InputLabel>
                                                            <Select
                                                                labelId="answer-label"
                                                                label="Answer"
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    setFieldValue(`questions[${index}].answer`, e.target.value);
                                                                }}
                                                                name={`questions[${index}].answer`}
                                                                value={question.answer}
                                                                error={!!touched.questions && !!errors.questions && !!errors.questions[index] && !!errors.questions[index].answer}
                                                            >
                                                                <MenuItem value={"a"}>A</MenuItem>
                                                                <MenuItem value={"b"}>B</MenuItem>
                                                                <MenuItem value={"c"}>C</MenuItem>
                                                                <MenuItem value={"d"}>D</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <Box display="flex" alignItems="center" gap="10px" sx={{ gridColumn: "span 4" }}>
                                                            {
                                                                (values.questions.length > 1) &&
                                                                <Button
                                                                    variant="outlined"
                                                                    color="error"
                                                                    onClick={() => {
                                                                        let newQuestions = values.questions;
                                                                        newQuestions[index].id && setRemovedQuestions([...removedQuestions, newQuestions[index].id]);
                                                                        newQuestions.splice(index, 1);
                                                                        setFieldValue("questions", newQuestions);
                                                                    }
                                                                    }
                                                                >
                                                                    Remove Question
                                                                </Button>
                                                            }
                                                            {(index === values.questions.length - 1) &&
                                                                <Button
                                                                    variant="outlined"
                                                                    color="success"
                                                                    onClick={() => {
                                                                        setFieldValue("questions", [...values.questions, {
                                                                            question: "",
                                                                            a: "",
                                                                            a_tip: "",
                                                                            b: "",
                                                                            b_tip: "",
                                                                            c: "",
                                                                            c_tip: "",
                                                                            d: "",
                                                                            d_tip: "",
                                                                            answer: ""
                                                                        }]);
                                                                    }
                                                                    }
                                                                >
                                                                    Add Question
                                                                </Button>
                                                            }
                                                        </Box>
                                                        {index !== values.questions.length - 1 &&
                                                            <Box component="hr" height="1px" sx={{ gridColumn: "span 4", mt: "30px", mb: "40px" }} width="100%" />
                                                        }
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    }

                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        disabled={isUploading}
                                        onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Save</Typography>
                                        {
                                            isUploading &&
                                            <CircularProgress size={16} sx={{ ml: "10px" }} />
                                        }
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default LessonDialog