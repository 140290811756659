import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';

function UserDialog({ targetUser, dialogOpen, handleClose, handleFormSubmit }) {

    const colors = tokens();

    var initialValues = {
        name: targetUser?.name ? targetUser?.name : "",
        bio: targetUser?.bio ? targetUser?.bio : "",
        gold: targetUser?.gold !== undefined && targetUser?.gold !== null ? targetUser?.gold : "",
        heart_count: targetUser?.heart_count !== undefined && targetUser?.heart_count !== null ? targetUser?.heart_count : "",
        photo: null,
        profile_photo_link: targetUser?.profile_photo_link ? targetUser?.profile_photo_link : ""
    };

    var validationObject = {
        name: yup.string().required("Field is required"),
        bio: yup.string(),
        gold: yup.number().min(0, "Cant be less than 0").required("Field is required"),
        heart_count: yup.number().min(0, "Cant be less than 0").required("Field is required"),
        photo: yup.mixed().nullable().when('profile_photo_link', {
            is: (profile_photo_link) => !profile_photo_link || profile_photo_link === "",
            then: yup.string().nonNullable("").required('Field is required')
        }),
        profile_photo_link: yup.string()
    }

    const userSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    Edit User
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={userSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box textAlign="center">
                                    <Typography variant="h6">Profile Photo</Typography>
                                    <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                        {(values.photo || (values.profile_photo_link && values.profile_photo_link !== "")) && <img alt='user profile' width="100px" src={values.photo ? URL.createObjectURL(values.photo) : values.profile_photo_link} />}
                                        <input id="photo" name="photo" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("photo", event.target.files[0]); }} hidden />
                                    </Button>
                                </Box>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"name"}
                                        value={values.name}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Biography"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={4}
                                        name={"bio"}
                                        value={values.bio}
                                        error={!!touched.bio && !!errors.bio}
                                        helperText={touched.bio && errors.bio}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Hearts"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"heart_count"}
                                        value={values.heart_count}
                                        error={!!touched.heart_count && !!errors.heart_count}
                                        helperText={touched.heart_count && errors.heart_count}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Gold"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"gold"}
                                        value={values.gold}
                                        error={!!touched.gold && !!errors.gold}
                                        helperText={touched.gold && errors.gold}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default UserDialog