import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem, Typography } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AdminContext } from '../../../../../context/AdminContext';
import ResourceDialog from './ResourceDialog';

function ResourcesDialog({ lesson, setLesson, dialogOpen, handleClose }) {
    const { token } = useContext(AdminContext);

    const [lessonState, setLessonState] = useState({ ...lesson });
    const [targetResource, setTargetResource] = useState(null);
    const [resourceDialogOpen, setResourceDialogOpen] = useState(false);

    const [resourceToRemove, setResourceToRemove] = useState(null);
    const [removeResourceDialogOpen, setRemoveResourceDialogOpen] = useState(false);

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const resourceAddMenuOpen = Boolean(menuAnchorEl);
    const handleOpenResourceAddMenuButtonClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleResourceAddMenuClose = () => {
        setMenuAnchorEl(null);
    }

    const removeResource = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}course/deleteSource?sourceId=${resourceToRemove.id}&token=${token}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setLesson({
                    ...lesson,
                    resources: lesson.resources.filter(resource => resource.id !== resourceToRemove.id)
                });
                setLessonState((prev) => ({
                    ...prev,
                    resources: prev.resources.filter(resource => resource.id !== resourceToRemove.id)
                })
                );
                setRemoveResourceDialogOpen(false);
                setResourceToRemove(null);
            }
        });
    }

    useEffect(() => {
        if (targetResource) {
            setResourceDialogOpen(true);
        }
    }, [targetResource]);

    return (
        <>
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={menuAnchorEl}
                open={resourceAddMenuOpen}
                onClose={handleResourceAddMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ mt: "40px" }}
            >
                <MenuItem
                    onClick={() => {
                        setTargetResource((prev) => ({ type: 0 }))
                        handleResourceAddMenuClose();
                    }}>
                    Add Link
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setTargetResource((prev) => ({ type: 1 }))
                        handleResourceAddMenuClose();
                    }}>
                    Add Document
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setTargetResource((prev) => ({ type: 2 }))
                        handleResourceAddMenuClose();
                    }}>
                    Add Wordset
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setTargetResource((prev) => ({ type: 3 }))
                        handleResourceAddMenuClose();
                    }}>
                    Add Reading
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setTargetResource((prev) => ({ type: 4 }))
                        handleResourceAddMenuClose();
                    }}>
                    Add Quiz
                </MenuItem>
            </Menu>

            <Dialog
                open={removeResourceDialogOpen}
                onClose={() => {
                    setRemoveResourceDialogOpen(false);
                    setResourceToRemove(null);
                }}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Are you sure you want to delete the resource?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot undo this operation but you can add new resource if you wish.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setRemoveResourceDialogOpen(false);
                        setResourceToRemove(null);
                    }}>Cancel</Button>
                    <Button onClick={() => removeResource()}>Delete</Button>
                </DialogActions>
            </Dialog>

            {targetResource &&
                <ResourceDialog
                    targetResource={targetResource}
                    courseId={lesson?.course_id}
                    lessonId={lesson?.id}
                    dialogOpen={resourceDialogOpen}
                    handleClose={() => {
                        setResourceDialogOpen(false);
                        setTargetResource(null);
                    }}
                    onFormSubmited={(resource) => {
                        let isResourceExist = lessonState.resources.find((r) => r.id === resource.id);
                        if (isResourceExist) {
                            console.log(resource);
                            console.log(resource);
                            console.log(resource);
                            setLesson((prev) => ({
                                ...prev,
                                resources: prev.resources.map((r) => r.id === resource.id ? resource : r)
                            }));
                            setLessonState((prev) => ({
                                ...prev,
                                resources: prev.resources.map((r) => r.id === resource.id ? resource : r)
                            }));
                        } else {
                            setLesson({
                                ...lesson,
                                resources: [...lesson.resources, resource]
                            });
                            setLessonState((prev) => ({
                                ...prev,
                                resources: [...prev.resources, resource]
                            }));
                        }
                        setResourceDialogOpen(false);
                        setTargetResource(null);
                    }}
                />
            }

            {lessonState &&
                <Dialog
                    open={dialogOpen}
                    onClose={
                        () => {
                            setRemoveResourceDialogOpen(false);
                            setResourceDialogOpen(false);
                            setTargetResource(null);
                            setResourceToRemove(null);
                            setLessonState(null);
                            handleClose();
                        }
                    }
                    fullWidth
                    maxWidth="md"
                >
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center" gap="10px">
                            <DialogTitle variant='h2' >
                                Resources
                            </DialogTitle>
                            <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={(e) => {
                                handleOpenResourceAddMenuButtonClick(e);
                            }} >
                                <Typography variant="button" color="white" fontSize={10}>
                                    Add New Resource
                                </Typography>
                            </Button>
                        </Box>
                        <Button
                            variant='outlined'
                            color='error'
                            sx={{ minWidth: "30px", width: "30px", height: "30px", m: 1, borderRadius: "50%" }}
                            onClick={() => {
                                setRemoveResourceDialogOpen(false);
                                setResourceDialogOpen(false);
                                setTargetResource(null);
                                setResourceToRemove(null);
                                setLessonState(null);
                                handleClose();
                            }}>
                            <Typography variant="button" color="error" fontSize={15}>
                                X
                            </Typography>
                        </Button>
                    </Box>

                    <DialogContent dividers>
                        <Box display="grid" gridTemplateColumns="repeat(3, minmax(0, 1fr))" gap="10px">
                            {(lessonState.resources && lessonState.resources.length > 0) ? lessonState.resources.map((resource, index) => (
                                <Box key={index} gap="5px" display="flex" flexDirection="column" alignItems="center" sx={{ gridColumn: "span 1", border: "1px solid #ccc", borderRadius: "5px", padding: "10px" }}>
                                    <Typography variant="h3" color="primary">
                                        {resource.title}
                                    </Typography>
                                    <Typography variant="caption" color="primary">
                                        {resource.type === 0 ? "Link" : resource.type === 1 ? "Document" : resource.type === 2 ? "Wordset" : resource.type === 3 ? "Reading" : "Quiz"}
                                    </Typography>
                                    <Box display="flex" alignItems="center" gap="10px" mt="5px">
                                        <Button variant='contained' color='info' sx={{ m: 1, width: '10ch' }}
                                            onClick={() => {
                                                setTargetResource(resource);
                                            }}>
                                            <Typography variant="button" color="white" fontSize={10}>
                                                Edit
                                            </Typography>
                                        </Button>
                                        <Button variant='contained' color='error' sx={{ m: 1, width: '10ch' }} onClick={() => {
                                            setResourceToRemove(resource);
                                            setRemoveResourceDialogOpen(true);
                                        }} >
                                            <Typography variant="button" color="white" fontSize={10}>
                                                Remove
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            ))
                                :
                                <Typography variant="button" color="primary" fontSize={10}>
                                    No resources available
                                </Typography>
                            }
                        </Box>
                    </DialogContent>

                </Dialog>
            }
        </>
    )
}

export default ResourcesDialog