import { Box, Dialog, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { tokens } from '../../../theme'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { AdminContext } from '../../../context/AdminContext';
import * as XLSX from 'xlsx';

function CampaignBox({ campaign, removeCampaign }) {
    const { token } = useContext(AdminContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const downloadCodes = (onlyUsables, csv) => {
        fetchCoupons(onlyUsables, (err, coupons) => {
            if (!err) {
                if (csv) {
                    let couponsToExport = [...coupons].map(cp => {
                        delete cp.diamond;
                        return cp;
                    })
                    const worksheetApprovers = XLSX.utils.json_to_sheet(couponsToExport);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheetApprovers, "Coupon Codes");
                    XLSX.writeFile(workbook, `${campaign.campaign_title}-coupon-codes-${(new Date()).toISOString()}.csv`);
                    setOpenDialog(false);
                } else {
                    coupons = coupons.map(cp => {
                        return cp.code;
                    })
                    const element = document.createElement('a');
                    const file = new Blob([coupons.join('\n')], { type: 'text/plain' });
                    element.href = URL.createObjectURL(file);
                    element.download = `${campaign.campaign_title}-coupon-codes-${(new Date()).toISOString()}.txt`;
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                    setOpenDialog(false);
                }
            }
        })
    }


    const fetchCoupons = (onlyUsables, cb) => {
        axios.get(`${process.env.REACT_APP_API_URL}coupon/getCouponsWithCampaignTitle?campaignTitle=${campaign.campaign_title}&token=${token}&onlyUsables=${onlyUsables}`)
            .catch((err) => {
                console.log("err: " + err);
                cb(err, null);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    cb(null, response.data.coupons);
                }
            });
    }

    const colors = tokens();
    return (
        <Box mt={"30px"} sx={{ gridColumn: "span 1" }} >
            <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
                <DialogTitle textAlign="center" variant='h3' >Download Codes</DialogTitle>
                <List sx={{ pt: 0 }}>
                    <ListItem disableGutters>
                        <ListItemButton onClick={() => downloadCodes(false, true)}>
                            <ListItemText primary={"Download All (csv)"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemButton onClick={() => downloadCodes(false, false)}>
                            <ListItemText primary={"Download All (txt)"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemButton onClick={() => downloadCodes(true, true)}>
                            <ListItemText primary={"Download Only Usables (csv)"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemButton onClick={() => downloadCodes(true, false)}>
                            <ListItemText primary={"Download Only Usables (txt)"} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Dialog>
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {campaign.coupon_count > 1 ?
                    <MenuItem onClick={() => {
                        setOpenDialog(true);
                        handleClose();
                    }}>
                        Download Coupon Codes
                    </MenuItem>
                    :
                    <MenuItem onClick={() => {
                        navigator.clipboard.writeText(campaign.code);
                        handleClose();
                    }}>
                        Copy Coupon Code
                    </MenuItem>
                }
                {/* <MenuItem onClick={() => {
                    window.location.href = `/campaigns/${campaign.campaign_title}`
                }}>
                    Details
                </MenuItem> */}
                <MenuItem onClick={() => {
                    removeCampaign(campaign);
                    handleClose()
                }}>
                    Remove
                </MenuItem>
            </Menu>

            <Box position="relative" bgcolor={colors.grey[900]} borderRadius="10px">
                <Box position="absolute" top={5} right={5}>

                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>

                </Box>
                <Box>
                    <Box mt={"5px"} mr={"35px"} p="15px" overflow={"hidden"}>
                        <Tooltip title={campaign.campaign_title}>
                            <Typography variant='h3' fontWeight="600">
                                {campaign.campaign_title} {campaign.coupon_count === 1 ? `(${campaign.code})` : ""}
                            </Typography>
                        </Tooltip>

                        <hr />

                        <Box display={"flex"} justifyContent={"space-between"} px={"5px"}>
                            <Box>
                                <Typography variant='h6' >
                                    Coupons: {campaign.coupon_count}
                                </Typography>

                                <Typography variant='h6' >
                                    Usage: {campaign.total_usage_limit - campaign.total_usage_count}/{campaign.total_usage_limit}
                                </Typography>
                                <Typography variant='h6' >
                                    Start: {new Date(campaign.start_date).toLocaleDateString()}
                                </Typography>

                                <Typography variant='h6' >
                                    End: {new Date(campaign.end_date).toLocaleDateString()}
                                </Typography>
                            </Box>

                            <Box>
                                <Typography variant='h6' >
                                    {campaign.heart ? <b>Heart: {campaign.heart}</b> : "Heart: -"}
                                </Typography>
                                <Typography variant='h6' >
                                    {campaign.gold ? <b>Gold: {campaign.gold}</b> : "Gold: -"}
                                </Typography>
                                <Typography variant='h6' >
                                    {campaign.premium && campaign.premium !== "none" ? <b>Premium: {campaign.premium}</b> : "Premium: -"}
                                </Typography>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default CampaignBox