import React, { useContext } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { AdminContext } from '../../../../context/AdminContext';
import { tokens } from '../../../../theme';

function ReadingCategoryDialog({ targetCategory, dialogOpen, handleClose }) {
    const colors = tokens()
    const { token } = useContext(AdminContext);

    var initialValues = {
        title: targetCategory?.title ? targetCategory?.title : "",
        thumbnail: null,
        thumbnail_link: targetCategory?.thumbnail_link ? targetCategory?.thumbnail_link : "",
        translations: targetCategory?.translations ? targetCategory.translations : [{
            translation: "",
            language: "turkish"
        },
        {
            translation: "",
            language: "german"
        },
        {
            translation: "",
            language: "spanish"
        },
        {
            translation: "",
            language: "french"
        },
        {
            translation: "",
            language: "portuguese"
        },
        {
            translation: "",
            language: "italian"
        }],
    }

    var validationObject = {
        title: yup.string().required("required"),
        thumbnail_link: yup.string(),
        thumbnail: yup.mixed().nullable(),
        translations: yup.array().of(
            yup.object({
                translation: yup.string().required("required"),
                language: yup.string().required("required")
            }).required("required")
        ).required("required")
    }

    const categorySchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        if (values.thumbnail) {
            await axios.post(`${process.env.REACT_APP_API_URL}reading/uploadThumbnail?token=${token}`, {
                thumbnail: values.thumbnail
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        values.thumbnail_path = response.data.thumbnailPath;
                    }
                });
        }
        delete values.thumbnail_link;
        delete values.thumbnail;
        if (!targetCategory) {
            await axios.post(`${process.env.REACT_APP_API_URL}reading/createReadingCategoryWithTranslations?token=${token}`, {
                ...values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose(true)
                }
            });
        } else {
            await axios.patch(`${process.env.REACT_APP_API_URL}reading/updateReadingCategoryWithTranslations?categoryId=${targetCategory.id}&token=${token}`, {
                ...values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose(true)
                }
            });
        }

    }

    const performTranslate = (category, languageSymbol, cb) => {
        axios.patch(`${process.env.REACT_APP_API_URL}translateWithBing`, {
            sentence: category,
            languageSymbol: languageSymbol
        }).catch((err) => {
            console.log("err", err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                cb(response.data.translation);
            } else {
                cb("-");
            }
        })

    }

    const translateCategory = (category, translations, setFieldValue) => {
        performTranslate(category, "tr", (translation) => {
            var ix = translations.findIndex((t) => t.language === "turkish");
            setFieldValue(`translations[${ix}].translation`, translation)
        });
        performTranslate(category, "de", (translation) => {
            var ix = translations.findIndex((t) => t.language === "german");
            setFieldValue(`translations[${ix}].translation`, translation)
        });
        performTranslate(category, "es", (translation) => {
            var ix = translations.findIndex((t) => t.language === "spanish");
            setFieldValue(`translations[${ix}].translation`, translation)
        });
        performTranslate(category, "fr", (translation) => {
            var ix = translations.findIndex((t) => t.language === "french");
            setFieldValue(`translations[${ix}].translation`, translation)
        });
        performTranslate(category, "pt-PT", (translation) => {
            var ix = translations.findIndex((t) => t.language === "portuguese");
            setFieldValue(`translations[${ix}].translation`, translation)
        });
        performTranslate(category, "it", (translation) => {
            var ix = translations.findIndex((t) => t.language === "italian");
            setFieldValue(`translations[${ix}].translation`, translation)
        });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={() => handleClose(false)}>
            <Box mx="20px" mt="20px" textAlign="center">
                {targetCategory &&
                    <Typography variant='h3'>
                        Edit Category ({targetCategory?.title})
                    </Typography>
                }
                {!targetCategory &&
                    <Typography variant='h3'>
                        New Category
                    </Typography>
                }
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={categorySchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(6, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <Box textAlign="center" sx={{ gridColumn: "span 6", marginTop: "20px" }}>
                                        <Typography variant="h6">Thumbnail Image</Typography>
                                        <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                            {(values.thumbnail || (values.thumbnail_link && values.thumbnail_link !== "")) && <img alt='thumbnail' width="140px" src={values.thumbnail ? URL.createObjectURL(values.thumbnail) : values.thumbnail_link} />}
                                            <input id="thumbnail" name="thumbnail" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail", event.target.files[0]); }} hidden />
                                        </Button>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Title"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 6", marginTop: "20px" }}
                                    />

                                    <Button color="success" variant="contained" sx={{ gridColumn: "3 / span 2", marginTop: "20px" }} onClick={() => translateCategory(values.title, values.translations, setFieldValue)}>
                                        <Typography>Translate</Typography>
                                    </Button>

                                    {values.translations.map((translation, j) =>
                                        <TextField
                                            key={`translation-${j}`}
                                            variant="filled"
                                            type="text"
                                            label={`Word(${translation.language})`}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"translations[" + j + "].translation"}
                                            value={values.translations[j].translation}
                                            error={!!touched?.translations && !!touched?.translations[j]?.translation && !!errors?.translations && !!errors?.translations[j]?.translation}
                                            helperText={touched?.translations && touched?.translations[j]?.translation && errors?.translations && errors?.translations[j]?.translation}
                                            sx={{ gridColumn: "span 3" }}
                                        />
                                    )}
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ReadingCategoryDialog