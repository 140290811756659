import React, { useContext, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { AdminContext } from '../../../../context/AdminContext';
import { tokens } from '../../../../theme';

function BookDialog({ targetBook, dialogOpen, handleClose }) {
    const { token } = useContext(AdminContext);
    const colors = tokens();
    const [isUploading, setUploading] = useState(false);

    var initialValues = {
        title: targetBook?.title ? targetBook?.title : "",
        author: targetBook?.author ? targetBook?.author : "",
        genre: targetBook?.genre ? targetBook?.genre : "",
        wordismo_original: targetBook?.wordismo_original ? targetBook?.wordismo_original === 1 : false,
        level_category: targetBook?.level_category ? targetBook?.level_category : "a1",
        thumbnail: null,
        thumbnail_link: targetBook?.thumbnail_link ? targetBook?.thumbnail_link : "",
        translations: targetBook?.translations ? targetBook.translations : [{
            title_translation: "",
            genre_translation: "",
            language: "turkish"
        },
        {
            title_translation: "",
            genre_translation: "",
            language: "german"
        },
        {
            title_translation: "",
            genre_translation: "",
            language: "spanish"
        },
        {
            title_translation: "",
            genre_translation: "",
            language: "french"
        },
        {
            title_translation: "",
            genre_translation: "",
            language: "portuguese"
        },
        {
            title_translation: "",
            genre_translation: "",
            language: "italian"
        }],
    }

    var validationObject = {
        title: yup.string().required("required"),
        author: yup.string().when('wordismo_original', {
            is: (wordismo_original) => !wordismo_original,
            then: () => yup.string().required("required")
        }),
        genre: yup.string().required("required"),
        wordismo_original: yup.boolean(),
        thumbnail: yup.mixed().nullable().when('thumbnail_link', {
            is: (thumbnail_link) => !thumbnail_link || thumbnail_link === "",
            then: () => yup.string().nonNullable("").required('Field is required')
        }),
        thumbnail_link: yup.string(),
        translations: yup.array().of(
            yup.object({
                title_translation: yup.string().required("required"),
                genre_translation: yup.string().required("required"),
                language: yup.string().required("required")
            }).required("required")
        ).required("required")
    }

    const bookSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        setUploading(true);
        if (values.thumbnail) {
            await axios.post(`${process.env.REACT_APP_API_URL}reading/uploadThumbnail?token=${token}`, {
                thumbnail: values.thumbnail
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        delete values.thumbnail;
                        delete values.thumbnail_link;
                        values.thumbnail_path = response.data.thumbnailPath;
                    }
                });
        }

        let book = { ...values };
        delete book.thumbnail;
        delete book.thumbnail_link;
        if (book.wordismo_original) {
            book.author = "Wordismo Original";
        }

        if (!targetBook) {
            await axios.post(`${process.env.REACT_APP_API_URL}reading/createBookWithTranslations?token=${token}`, {
                ...values
            }).catch((err) => {
                setUploading(false);
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setUploading(false);
                    handleClose(true)
                }
            });
        } else {
            await axios.patch(`${process.env.REACT_APP_API_URL}reading/updateBookWithTranslations?bookId=${targetBook.id}&token=${token}`, {
                ...book
            }).catch((err) => {
                setUploading(false);
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setUploading(false);
                    handleClose(true)
                }
            });
        }

    }

    const performTranslate = (wordToTranslate, languageSymbol, cb) => {
        axios.patch(`${process.env.REACT_APP_API_URL}translateWithBing`, {
            sentence: wordToTranslate,
            languageSymbol: languageSymbol
        }).catch((err) => {
            console.log("err", err);
        }).then((response) => {
            console.log(wordToTranslate, languageSymbol, response.data.translation);
            if (response && response.data.success === true && response.data.status === 200) {
                cb(response.data.translation);
            } else {
                cb("-");
            }
        })

    }

    const translateCategory = (title, genre, translations, setFieldValue) => {
        performTranslate(title, "tr", (translation) => {
            var ix = translations.findIndex((t) => t.language === "turkish");
            setFieldValue(`translations[${ix}].title_translation`, translation)
        });
        performTranslate(title, "de", (translation) => {
            var ix = translations.findIndex((t) => t.language === "german");
            setFieldValue(`translations[${ix}].title_translation`, translation)
        });
        performTranslate(title, "es", (translation) => {
            var ix = translations.findIndex((t) => t.language === "spanish");
            setFieldValue(`translations[${ix}].title_translation`, translation)
        });
        performTranslate(title, "fr", (translation) => {
            var ix = translations.findIndex((t) => t.language === "french");
            setFieldValue(`translations[${ix}].title_translation`, translation)
        });
        performTranslate(title, "pt-PT", (translation) => {
            var ix = translations.findIndex((t) => t.language === "portuguese");
            setFieldValue(`translations[${ix}].title_translation`, translation)
        });
        performTranslate(title, "it", (translation) => {
            var ix = translations.findIndex((t) => t.language === "italian");
            setFieldValue(`translations[${ix}].title_translation`, translation)
        });
        performTranslate(genre, "tr", (translation) => {
            var ix = translations.findIndex((t) => t.language === "turkish");
            setFieldValue(`translations[${ix}].genre_translation`, translation)
        });
        performTranslate(genre, "de", (translation) => {
            var ix = translations.findIndex((t) => t.language === "german");
            setFieldValue(`translations[${ix}].genre_translation`, translation)
        });
        performTranslate(genre, "es", (translation) => {
            var ix = translations.findIndex((t) => t.language === "spanish");
            setFieldValue(`translations[${ix}].genre_translation`, translation)
        });
        performTranslate(genre, "fr", (translation) => {
            var ix = translations.findIndex((t) => t.language === "french");
            setFieldValue(`translations[${ix}].genre_translation`, translation)
        });
        performTranslate(genre, "pt-PT", (translation) => {
            var ix = translations.findIndex((t) => t.language === "portuguese");
            setFieldValue(`translations[${ix}].genre_translation`, translation)
        });
        performTranslate(genre, "it", (translation) => {
            var ix = translations.findIndex((t) => t.language === "italian");
            setFieldValue(`translations[${ix}].genre_translation`, translation)
        });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={dialogOpen}
            maxWidth="md"
            onClose={() => handleClose(false)}>
            <Box mx="20px" mt="20px" textAlign="center">
                {targetBook &&
                    <Typography variant='h3'>
                        Edit Book ({targetBook?.title})
                    </Typography>
                }
                {!targetBook &&
                    <Typography variant='h3'>
                        New Book
                    </Typography>
                }
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={bookSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box textAlign="center">
                                    <Typography variant="h6">Thumbnail Photo</Typography>
                                    <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                        {(values.thumbnail || (values.thumbnail_link && values.thumbnail_link !== "")) && <img alt='thumbnail' width="100px" src={values.thumbnail ? URL.createObjectURL(values.thumbnail) : values.thumbnail_link} />}
                                        <input id="thumbnail" name="thumbnail" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail", event.target.files[0]); }} hidden />
                                    </Button>
                                </Box>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(6, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Title"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 6", marginTop: "20px" }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.wordismo_original}
                                                onChange={handleChange}
                                                name="wordismo_original"
                                                color="primary"
                                            />
                                        }
                                        label="Wordismo Original"
                                        sx={{ gridColumn: "span 3" }}
                                    />

                                    {
                                        !values.wordismo_original &&
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Author"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"author"}
                                            value={values.author}
                                            error={!!touched.author && !!errors.author}
                                            helperText={touched.author && errors.author}
                                            sx={{ gridColumn: "span 6" }}
                                        />
                                    }

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Genre"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"genre"}
                                        value={values.genre}
                                        error={!!touched.genre && !!errors.genre}
                                        helperText={touched.genre && errors.genre}
                                        sx={{ gridColumn: "span 6" }}
                                    />

                                    <FormControl variant="filled"
                                        sx={{ gridColumn: "span 6" }}>
                                        <InputLabel id="filter-label4">Level Category</InputLabel>
                                        <Select
                                            labelId="filter-label4"
                                            label="Level Category"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"level_category"}
                                            value={values.level_category}
                                            error={!!touched.level_category && !!errors.level_category}
                                        >
                                            <MenuItem value={"a1"}>A1</MenuItem>
                                            <MenuItem value={"a2"}>A2</MenuItem>
                                            <MenuItem value={"b1"}>B1</MenuItem>
                                            <MenuItem value={"b2"}>B2</MenuItem>
                                            <MenuItem value={"c1"}>C1</MenuItem>
                                            <MenuItem value={"c2"}>C2</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Button color="success" variant="contained" sx={{ gridColumn: "3 / span 2", marginTop: "20px" }} onClick={() => translateCategory(values.title, values.genre, values.translations, setFieldValue)}>
                                        <Typography>Translate</Typography>
                                    </Button>

                                    {values.translations.map((translation, j) =>
                                        <Box key={`translations-${j}`} display="grid" gap="15px" gridTemplateColumns="repeat(6, minmax(0, 1fr))" gridColumn={"span 6"}>
                                            <TextField
                                                key={`title-translation-${j}`}
                                                variant="filled"
                                                type="text"
                                                label={`Title(${translation.language})`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"translations[" + j + "].title_translation"}
                                                value={values.translations[j].title_translation}
                                                error={!!touched?.translations && !!touched?.translations[j]?.title_translation && !!errors?.translations && !!errors?.translations[j]?.title_translation}
                                                helperText={touched?.translations && touched?.translations[j]?.title_translation && errors?.translations && errors?.translations[j]?.title_translation}
                                                sx={{ gridColumn: "span 3" }}
                                            />
                                            <TextField
                                                key={`genre-translation-${j}`}
                                                variant="filled"
                                                type="text"
                                                label={`Genre(${translation.language})`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"translations[" + j + "].genre_translation"}
                                                value={values.translations[j].genre_translation}
                                                error={!!touched?.translations && !!touched?.translations[j]?.genre_translation && !!errors?.translations && !!errors?.translations[j]?.genre_translation}
                                                helperText={touched?.translations && touched?.translations[j]?.genre_translation && errors?.translations && errors?.translations[j]?.genre_translation}
                                                sx={{ gridColumn: "span 3" }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Save</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default BookDialog