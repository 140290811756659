import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import React, { useContext, useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoIcon from '@mui/icons-material/Info';
import { tokens } from '../../../../theme';
import LessonDialog from './LessonDialog';
import axios from 'axios';
import { AdminContext } from '../../../../context/AdminContext';
import ResourcesDialog from './ResourcesDialog';

function LessonTable({ lessons, setLessons, courseId, sectionId, loading, fetchCourse }) {
    const colors = tokens();
    const { token } = useContext(AdminContext);
    const [targetLesson, setTargetLesson] = useState(null);
    const [lessonDialogOpen, setLessonDialogOpen] = useState(false);

    const [lessonToRemove, setLessonToRemove] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    const [targetLessonForResources, setTargetLessonForResources] = useState(null);
    const [resourcesDialogOpen, setResourcesDialogOpen] = useState(false);

    useEffect(() => {
        if (targetLesson) {
            setLessonDialogOpen(true);
        }
    }, [targetLesson]);

    useEffect(() => {
        if (lessonToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [lessonToRemove]);

    useEffect(() => {
        if (targetLessonForResources) {
            setResourcesDialogOpen(true);
        }
    }, [targetLessonForResources]);

    const changeLessonsPositions = async (lessonIndex, moveUp) => {
        if (moveUp && lessonIndex === 0) return;
        if (!moveUp && lessonIndex === lessons.length - 1) return;

        axios.patch(`${process.env.REACT_APP_API_URL}course/changeLessonsPositions?lesson1Id=${lessons[lessonIndex].id}&lesson2Id=${lessons[moveUp ? lessonIndex - 1 : lessonIndex + 1].id}&lesson1Position=${lessons[lessonIndex].position}&lesson2Position=${lessons[moveUp ? lessonIndex - 1 : lessonIndex + 1].position}&token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchCourse();
                }
            });
    };

    const removeLesson = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}course/deleteLesson?lessonId=${lessonToRemove.id}&token=${token}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setRemoveDialogOpen(false);
                setLessonToRemove(null);
                fetchCourse();
            }
        });
    }

    return (
        <>
            <LessonDialog
                courseId={courseId}
                sectionId={sectionId}
                nextPosition={lessons.length + 1}
                targetLesson={targetLesson}
                dialogOpen={lessonDialogOpen}
                handleClose={() => {
                    setLessonDialogOpen(false);
                    setTargetLesson(null);
                }}
                onFormSubmited={() => {
                    setLessonDialogOpen(false);
                    setTargetLesson(null);
                    fetchCourse();
                }} />

            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={() => {
                    setRemoveDialogOpen(false);
                    setLessonToRemove(null);
                }}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{"Are you sure you want to delete the lesson?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You cannot undo this operation but you can add new lesson if you wish.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setRemoveDialogOpen(false);
                        setLessonToRemove(null);
                    }}>Cancel</Button>
                    <Button onClick={() => removeLesson()}>Delete</Button>
                </DialogActions>
            </Dialog>

            {targetLessonForResources &&
                <ResourcesDialog
                    lesson={targetLessonForResources}
                    setLesson={(lesson) => {
                        let newLessons = [...lessons];
                        let index = newLessons.findIndex(l => l.id === lesson.id);
                        newLessons[index] = lesson;
                        setLessons(newLessons);
                    }
                    }
                    dialogOpen={resourcesDialogOpen}
                    handleClose={(reFetch) => {
                        setResourcesDialogOpen(false);
                        setTargetLessonForResources(null);
                        fetchCourse();
                    }}
                />
            }

            <Box mt="20px" sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography variant="h6" color="primary" fontWeight="bold">
                    Lessons
                </Typography>
                <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                    setLessonDialogOpen(true);
                }} >
                    <Typography variant="button" color="white" fontSize={10}>
                        Add New Lesson
                    </Typography>
                </Button>
            </Box>
            {(lessons && lessons.length > 0) &&
                <DataGrid
                    rows={lessons}
                    columns={[
                        { field: 'position', headerName: 'Position' },
                        { field: 'type', headerName: 'Type', renderCell: (params) => params.value === 0 ? "Video" : params.value === 1 ? "PDF" : "Quiz" },
                        { field: 'title', headerName: 'Title', flex: 1 },
                        {
                            field: 'content', headerName: 'Content', renderCell: (params) => {
                                if (params.row.type === 0) {
                                    return <a href={params.row.video_link} target="_blank" rel="noreferrer" style={{ cursor: "pointer", color: colors.blueAccent[400], textDecoration: "underline" }}>See Video</a>
                                } else if (params.row.type === 1) {
                                    return <a href={params.row.pdf_link} target="_blank" rel="noreferrer" style={{ cursor: "pointer", color: colors.blueAccent[400], textDecoration: "underline" }}>See PDF({params.row.pdf_name})</a>
                                } else {
                                    return params.row.question_count + " Questions"
                                }
                            },
                            flex: 0.3
                        },
                        { field: 'completed_count', headerName: 'Completions' },
                        { field: 'resource_count', headerName: 'Resource Count' },
                        { field: 'previewable', headerName: 'Previewable', type: 'boolean' },
                        { field: 'created_date', headerName: 'Created Date', renderCell: (params) => new Date(params.value).toLocaleDateString() }, {
                            field: 'actions',
                            type: 'actions',
                            sortable: false,
                            width: 80,
                            getActions: (params) => [
                                <GridActionsCellItem
                                    icon={<EditIcon />}
                                    label="Edit Lesson"
                                    onClick={() => setTargetLesson(params.row)}
                                    showInMenu
                                />,
                                <GridActionsCellItem
                                    icon={<DeleteIcon />}
                                    label="Delete Lesson"
                                    onClick={() => setLessonToRemove(params.row)}
                                    showInMenu
                                />,
                                ...(params.row.position !== 1 ?
                                    [
                                        <GridActionsCellItem
                                            icon={<ArrowDropUpIcon />}
                                            label="Move Up"
                                            onClick={() => changeLessonsPositions(params.row.position - 1, true)}
                                            showInMenu
                                        />
                                    ]
                                    : []),
                                ...(params.row.position !== lessons.length ?
                                    [
                                        <GridActionsCellItem
                                            icon={<ArrowDropDownIcon />}
                                            label="Move Down"
                                            onClick={() => changeLessonsPositions(params.row.position - 1, false)}
                                            showInMenu
                                        />
                                    ]
                                    : []),
                                <GridActionsCellItem
                                    icon={<InfoIcon />}
                                    label="Resources"
                                    onClick={() => setTargetLessonForResources(lessons.find(lesson => lesson.id === params.row.id))}
                                    showInMenu
                                />
                            ],
                        },
                    ]}
                    rowCount={lessons.length}
                    hideFooter
                    loading={loading}
                    disableRowSelectionOnClick
                    sx={{
                        ".MuiDataGrid-columnHeaders": {
                            background: colors.grey[900]
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                        mt: "10px",
                    }}
                />
            }
        </>
    )
}

export default LessonTable