import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../global/Header'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useParams } from 'react-router-dom';
import CourseDialog from '../CourseDialog';
import SectionDialog from './SectionDialog';
import SectionRemoveDialog from './SectionRemoveDialog';
import { AdminContext } from '../../../context/AdminContext';
import LessonTable from './LessonTable';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../../theme';

function CourseDetailsPage() {
    const { courseId } = useParams();
    const { token } = useContext(AdminContext);
    const colors = tokens();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [courseDialogOpen, setCourseDialogOpen] = useState(false);

    const [targetSection, setTargetSection] = useState(null);
    const [sectionDialogOpen, setSectionDialogOpen] = useState(false);

    const [sectionToRemove, setSectionToRemove] = useState(null);
    const [sectionRemoveDialogOpen, setSectionRemoveDialogOpen] = useState(false);

    const [reviews, setReviews] = useState([]);
    const [isReviewsLoading, setReviewsLoading] = useState(true);
    const [reviewsOrderModel, setReviewsOrderModel] = useState(null);
    const [reviewsRowCount, setReviewsRowCount] = useState(0);
    const [reviewsPaginationModel, setReviewsPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });


    const fetchCourse = () => {
        axios.get(`${process.env.REACT_APP_API_URL}course/getCourseWithDetails?courseId=${courseId}`)
            .catch((err) => {
                console.log("err: " + err);
                setCourse(null);
                setLoading(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCourse(response.data.course);
                    setLoading(false);
                }
            });
    };

    const fetchReviews = () => {
        setReviewsLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}course/getCourseReviews?courseId=${courseId}&startIndex=${reviewsPaginationModel.pageSize * reviewsPaginationModel.page}&limit=${reviewsPaginationModel.pageSize}${reviewsOrderModel ? `&orderColumn=${reviewsOrderModel.field}&orderType=${reviewsOrderModel.sort}` : ""}&token=${token}`)
            .catch((err) => {
                setReviews([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setReviews(response.data.reviews);
                    setReviewsRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setReviewsLoading(false);
            });
    };

    const changeCourseSectionsPositions = async (sectionIndex, moveUp) => {
        if (moveUp && sectionIndex === 0) return;
        if (!moveUp && sectionIndex === course.sections.length - 1) return;

        axios.patch(`${process.env.REACT_APP_API_URL}course/changeCourseSectionsPositions?section1Id=${course.sections[sectionIndex].id}&section2Id=${course.sections[moveUp ? sectionIndex - 1 : sectionIndex + 1].id}&section1Position=${course.sections[sectionIndex].position}&section2Position=${course.sections[moveUp ? sectionIndex - 1 : sectionIndex + 1].position}&token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchCourse();
                }
            });
    };

    useEffect(() => {
        fetchCourse();
    }, []);

    useEffect(() => {
        fetchReviews();
    }, [reviewsPaginationModel, reviewsOrderModel]);

    useEffect(() => {
        if (targetSection) {
            setSectionDialogOpen(true);
        }
    }, [targetSection]);

    useEffect(() => {
        if (sectionToRemove) {
            setSectionRemoveDialogOpen(true);
        }
    }, [sectionToRemove]);

    if (loading && !course) {
        return (
            <Box sx={{ p: "75px", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                {/* <CircularProgress /> */}
                <CircularProgress color="primary" />
            </Box>
        )
    }


    return (
        <Box sx={{ p: "75px" }}>
            <CourseDialog
                targetCourse={course}
                dialogOpen={courseDialogOpen}
                handleClose={() => {
                    setCourseDialogOpen(false)
                }}
                onFormSubmited={() => {
                    setCourseDialogOpen(false);
                    fetchCourse();
                }} />
            <SectionDialog
                course={course}
                targetSection={targetSection}
                dialogOpen={sectionDialogOpen}
                handleClose={() => {
                    setTargetSection(null);
                    setSectionDialogOpen(false)
                }}
                onFormSubmited={() => {
                    setTargetSection(null);
                    setSectionDialogOpen(false);
                    fetchCourse();
                }} />
            <SectionRemoveDialog
                sectionId={sectionToRemove?.id}
                removeDialogOpen={sectionRemoveDialogOpen}
                handleRemoveDialogClose={() => {
                    setSectionRemoveDialogOpen(false);
                    setSectionToRemove(null);
                }}
                onCourseSectionRemoved={() => {
                    setSectionRemoveDialogOpen(false);
                    setSectionToRemove(null);
                    fetchCourse();
                }}
            />

            <Box display="flex" alignItems="center" gap="20px">
                <Header title={course?.title} subtitle="" />
                <Button variant='contained' color='success' sx={{ mb: "30px", width: '25ch' }} onClick={() => {
                    setCourseDialogOpen(true);
                }} >
                    Edit Course
                </Button>
            </Box>

            <Typography variant='h5' color="primary">
                {course?.description}
            </Typography>

            <Typography variant='body1' color="primary" mt="10px">
                {course?.details}
            </Typography>

            <Box mt="40px">
                <Box display="flex" alignItems="center" gap="10px">
                    <Typography variant="h5" color="primary" fontWeight="bold">
                        Sections
                    </Typography>
                    <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                        setSectionDialogOpen(true);
                    }} >
                        <Typography variant="button" color="white" fontSize={10}>
                            Add New Section
                        </Typography>
                    </Button>
                </Box>

                <Box mt="20px">
                    {course?.sections?.map((section, index) => (
                        <Box key={index} sx={{ mt: index === 0 ? 0 : "10px", p: 1, border: "1px solid", borderColor: "grey.300", borderRadius: "5px" }}>
                            <Box display="flex" alignItems="center" gap="10px">
                                <Typography variant="h6" color="primary" fontWeight="bold">
                                    Section {section.position}: {section.title}
                                </Typography>
                                <Typography variant="body1" color="primary">
                                    {section.description}
                                </Typography>
                                <Button variant='outlined' color='success'
                                    sx={{
                                        p: "8px",
                                        minWidth: "30px",
                                        minHeight: "30px",
                                        borderRadius: "50%"
                                    }}
                                    onClick={() => {
                                        setTargetSection(section);
                                    }} >
                                    <EditIcon />
                                </Button>
                                <Button variant='outlined' color='error'
                                    sx={{
                                        p: "8px",
                                        minWidth: "30px",
                                        minHeight: "30px",
                                        borderRadius: "50%"
                                    }}
                                    onClick={() => {
                                        setSectionToRemove(section);
                                    }} >
                                    <DeleteIcon />
                                </Button>
                                {index !== 0 &&
                                    <Button variant='outlined' color='info'
                                        sx={{
                                            p: "8px",
                                            minWidth: "30px",
                                            minHeight: "30px",
                                            borderRadius: "50%"
                                        }}
                                        onClick={() => {
                                            changeCourseSectionsPositions(index, true);
                                        }} >
                                        <ArrowDropUpIcon />
                                    </Button>
                                }
                                {index !== course.sections.length - 1 &&
                                    <Button variant='outlined' color='info'
                                        sx={{
                                            p: "8px",
                                            minWidth: "30px",
                                            minHeight: "30px",
                                            borderRadius: "50%"
                                        }}
                                        onClick={() => {
                                            changeCourseSectionsPositions(index, false);
                                        }} >
                                        <ArrowDropDownIcon />
                                    </Button>
                                }
                            </Box>
                            {
                                //lessons table
                            }

                            <LessonTable lessons={section.lessons} setLessons={
                                (lessons) => {
                                    setCourse((prevCourse) => {
                                        let newCourse = { ...prevCourse };
                                        newCourse.sections[index].lessons = lessons;
                                        return newCourse;
                                    });

                                }
                            } sectionId={section.id} courseId={courseId} loading={loading} fetchCourse={fetchCourse} />
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box mt="40px">
                <Typography variant="h5" color="primary" fontWeight="bold">
                    Reviews
                </Typography>
                {
                    reviews?.length > 0 ?
                        <Box mt="20px">
                            <DataGrid
                                rows={reviews}
                                columns={[
                                    {
                                        field: 'user', headerName: 'User', width: 150,
                                        renderCell: (params) => (
                                            //profile photo and name
                                            <Box display="flex" alignItems="center" gap="10px">
                                                <img src={params.row.user_profile_photo_link} alt="profile" style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
                                                <Typography variant="body1" color="primary">
                                                    {params.row.user_name}
                                                </Typography>
                                            </Box>
                                        ),
                                        sortable: false
                                    },
                                    { field: 'rating', headerName: 'Rating', width: 150 },
                                    { field: 'review', headerName: 'Review', flex: 1 },
                                    {
                                        field: 'created_date', headerName: 'Created Date', width: 200,
                                        renderCell: (params) => (
                                            <Typography variant="body1" color="primary">
                                                {new Date(params.row.created_date).toLocaleString()}
                                            </Typography>
                                        ),
                                    },
                                ]}
                                rowCount={reviewsRowCount}
                                loading={isReviewsLoading}
                                pageSizeOptions={[10, 25, 50, 75, 100]}
                                paginationModel={reviewsPaginationModel}
                                paginationMode="server"
                                onPaginationModelChange={setReviewsPaginationModel}
                                sortingMode='server'
                                onSortModelChange={(model) => {
                                    setReviewsOrderModel((prev) => model && model.length > 0 ? model[0] : null);
                                    setReviewsPaginationModel(prev => { return { ...prev, page: 0 } });
                                }}
                                disableColumnFilter
                                sx={{
                                    ".MuiDataGrid-columnHeaders": {
                                        background: colors.grey[900]
                                    }
                                }}
                            />
                        </Box>
                        :
                        < Box mt="20px">
                            <Typography variant="body1" color="primary">
                                There are no reviews for this course yet.
                            </Typography>
                        </Box>
                }
            </Box>
        </Box >
    )
}

export default CourseDetailsPage