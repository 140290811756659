import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { tokens } from '../../../theme'
import MoreVertIcon from '@mui/icons-material/MoreVert';

const languagePrefixes = {
    turkish: "tr",
    french: "fr",
    german: "de",
    italian: "it",
    portuguese: "pt",
    spanish: "sp"
}

function WordBox({ word, updateWord, removeWord }) {
    const colors = tokens();
    const [selectedLanguage, setSelectedLanguage] = useState("turkish");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box width="500px">
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    updateWord({
                        id: word.id,
                        english_word: word.english_word,
                        ...(word.image_link ? { image_link: word.image_link } : {}),
                        translation: word[languagePrefixes[selectedLanguage] + "_translated_word"],
                        language: selectedLanguage,
                        category: word.category,
                        subcategory: word.subcategory,
                        definition: word.definition,
                        example: word.example,
                        example_highlight: word.example_highlight,
                        example_translation: word[languagePrefixes[selectedLanguage] + "_translated_example"],
                        example_translation_highlight: word[languagePrefixes[selectedLanguage] + "_translated_example_highlight"],
                    });
                }}>
                    Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    removeWord({
                        id: word.id,
                        english_word: word.english_word,
                        translation: word[languagePrefixes[selectedLanguage] + "_translated_word"],
                        language: selectedLanguage,
                        category: word.category,
                        subcategory: word.subcategory,
                        definition: word.definition,
                        example: word.example,
                        example_highlight: word.example_highlight,
                        example_translation: word[languagePrefixes[selectedLanguage] + "_translated_example"],
                        example_translation_highlight: word[languagePrefixes[selectedLanguage] + "_translated_example_highlight"],
                    });
                }}>
                    Remove
                </MenuItem>
            </Menu>
            <Box position="relative" bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Box position="absolute" top={15} right={15}>
                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Box>
                        <Box display={"flex"} gap="10px">
                            {!!word.image_link && <Box component={"img"} src={word.image_link} width="120px" height="100px" sx={{
                                objectFit: "cover",
                            }} />}
                            <Box>
                                <Typography variant='h3' fontWeight="600">
                                    {word.english_word ? word.english_word : "-"}
                                </Typography>
                                <Typography variant='h3'>
                                    {word[languagePrefixes[selectedLanguage] + "_translated_word"] ? word[languagePrefixes[selectedLanguage] + "_translated_word"] : "-"}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" mt="10px" gap="10px">
                            <Tooltip title="Turkish">
                                <Box
                                    sx={{
                                        ...(selectedLanguage !== "turkish" ? {
                                            filter: "saturate(0%)",
                                            WebkitFilter: "saturate(0%)"
                                        } : {}),
                                        ":hover": {
                                            filter: "saturate(100%)",
                                            WebkitFilter: "saturate(100%)",
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => setSelectedLanguage("turkish")}
                                    component="img"
                                    src='./flags/turkey.png'
                                />
                            </Tooltip>

                            <Tooltip title="French">
                                <Box
                                    sx={{
                                        ...(selectedLanguage !== "french" ? {
                                            filter: "saturate(0%)",
                                            WebkitFilter: "saturate(0%)"
                                        } : {}),
                                        ":hover": {
                                            filter: "saturate(100%)",
                                            WebkitFilter: "saturate(100%)",
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => setSelectedLanguage("french")}
                                    component="img"
                                    src='./flags/france.png'
                                />
                            </Tooltip>

                            <Tooltip title="German">
                                <Box
                                    sx={{
                                        ...(selectedLanguage !== "german" ? {
                                            filter: "saturate(0%)",
                                            WebkitFilter: "saturate(0%)"
                                        } : {}),
                                        ":hover": {
                                            filter: "saturate(100%)",
                                            WebkitFilter: "saturate(100%)",
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => setSelectedLanguage("german")}
                                    component="img"
                                    src='./flags/germany.png'
                                />
                            </Tooltip>

                            <Tooltip title="Italian">
                                <Box
                                    sx={{
                                        ...(selectedLanguage !== "italian" ? {
                                            filter: "saturate(0%)",
                                            WebkitFilter: "saturate(0%)"
                                        } : {}),
                                        ":hover": {
                                            filter: "saturate(100%)",
                                            WebkitFilter: "saturate(100%)",
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => setSelectedLanguage("italian")}
                                    component="img"
                                    src='./flags/italy.png'
                                />
                            </Tooltip>

                            <Tooltip title="Portuguese">
                                <Box
                                    sx={{
                                        ...(selectedLanguage !== "portuguese" ? {
                                            filter: "saturate(0%)",
                                            WebkitFilter: "saturate(0%)"
                                        } : {}),
                                        ":hover": {
                                            filter: "saturate(100%)",
                                            WebkitFilter: "saturate(100%)",
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => setSelectedLanguage("portuguese")}
                                    component="img"
                                    src='./flags/portugal.png'
                                />
                            </Tooltip>

                            <Tooltip title="Spanish">
                                <Box
                                    sx={{
                                        ...(selectedLanguage !== "spanish" ? {
                                            filter: "saturate(0%)",
                                            WebkitFilter: "saturate(0%)"
                                        } : {}),
                                        ":hover": {
                                            filter: "saturate(100%)",
                                            WebkitFilter: "saturate(100%)",
                                            cursor: "pointer"
                                        }
                                    }}
                                    onClick={() => setSelectedLanguage("spanish")}
                                    component="img"
                                    src='./flags/spain.png'
                                />
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
                <Box component="hr" my="20px" border={"1px solid " + colors.grey[500]} />
                <Box>
                    <Typography variant='h5'>
                        {word.category_name ? word.category_name : "-"} - {word.subCategory_name ? word.subCategory_name : "-"}
                    </Typography>
                </Box>
                <Box mt="10px">
                    <Typography variant='h5' fontWeight={600} sx={{ textDecoration: "underline" }}>Definition</Typography>
                    <Typography variant='h6' >-{word.definition}</Typography>
                </Box>
                <Box mt="10px">
                    <Typography variant='h5' fontWeight={600} sx={{ textDecoration: "underline" }}>Examples</Typography>
                    <Typography variant='h6' >-{word.example ? word.example : "-"}</Typography>
                    <Typography variant='h6' >-{word[languagePrefixes[selectedLanguage] + "_translated_example"] ? word[languagePrefixes[selectedLanguage] + "_translated_example"] : "-"}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default WordBox