import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Checkbox, Chip, CircularProgress, Dialog, DialogContent, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../../theme';
import { AdminContext } from '../../../context/AdminContext';

function CourseDialog({ targetCourse, dialogOpen, handleClose, onFormSubmited }) {

    const colors = tokens();
    const { token } = useContext(AdminContext);
    const [courseCategories, setCourseCategories] = useState([]);
    const [allInstructors, setAllInstructors] = useState([]);

    const [isUploading, setUploading] = useState(false);

    var initialValues = {
        title: targetCourse?.title ? targetCourse.title : "",
        description: targetCourse?.description ? targetCourse.description : "",
        details: targetCourse?.details ? targetCourse.details : "",
        type: targetCourse?.type !== undefined && targetCourse?.type !== null ? targetCourse.type : 0,
        is_pro: targetCourse?.is_pro ? targetCourse.is_pro === 1 : false,
        language: targetCourse?.language ? targetCourse.language : "english",
        categories: targetCourse?.categories ? targetCourse.categories.map((category) => ({ id: category.id, title: category.title })) : [],
        instructors: targetCourse?.instructors ? targetCourse.instructors.map((instructor) => ({ id: instructor.id, name: instructor.name, profile_photo_link: instructor.profile_photo_link })) : [],
        thumbnail: null,
        thumbnail_link: targetCourse?.thumbnail_link ? targetCourse.thumbnail_link : ""
    };

    var validationObject = {
        title: yup.string().required("Field is required"),
        description: yup.string().required("Field is required"),
        details: yup.string().required("Field is required"),
        type: yup.number().min(0, "Choose a valid type").max(4, "Choose a valid type").required("Field is required"),
        is_pro: yup.boolean().required("Field is required"),
        language: yup.string().required("Field is required"),
        categories: yup.array().min(1, "Choose at least one category").required("Field is required"),
        instructors: yup.array().min(1, "Choose at least one instructor").required("Field is required"),
        thumbnail: yup.mixed().nullable().when('thumbnail_link', {
            is: (thumbnail_link) => !thumbnail_link || thumbnail_link === "",
            then: () => yup.string().nonNullable("").required('Field is required')
        }),
        thumbnail_link: yup.string()
    };

    const courseSchema = yup.object().shape(validationObject);

    const submitForm = async (values) => {
        setUploading(true);
        if (values.thumbnail) {
            await axios.post(`${process.env.REACT_APP_API_URL}course/uploadThumbnail?token=${token}`, {
                thumbnail: values.thumbnail
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        delete values.thumbnail;
                        delete values.thumbnail_link;
                        values.thumbnail_path = response.data.thumbnailPath;
                    }
                });
        }

        let instructors = values.instructors?.map((instructor) => ({ user_id: instructor.id })) || [];
        let categories = values.categories?.map((category) => ({ course_category_id: category.id })) || [];

        let course = { ...values };
        delete course.thumbnail;
        delete course.thumbnail_link;
        delete course.categories;
        delete course.instructors;

        if (targetCourse) {
            //update

            await axios.patch(`${process.env.REACT_APP_API_URL}course/updateCourse?courseId=${targetCourse.id}&token=${token}`, {
                course: course,
                instructors: instructors,
                categories: categories
            }).catch((err) => {
                console.log("err: " + err);
                setUploading(false);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setUploading(false);
                    onFormSubmited({ ...course, id: targetCourse.id });
                }
            });

        } else {
            //add

            await axios.post(`${process.env.REACT_APP_API_URL}course/?token=${token}`, {
                course: course,
                instructors: instructors,
                categories: categories
            }).catch((err) => {
                console.log("err: " + err);
                setUploading(false);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setUploading(false);
                    onFormSubmited();
                }
            });
        }

    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}course/getCourseCategories`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setCourseCategories(response.data.categories);
            } else {
                setCourseCategories([]);
            }
        });

        axios.get(`${process.env.REACT_APP_API_URL}user/getInstructors`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                setAllInstructors(response.data.instructors);
            } else {
                setAllInstructors([]);
            }
        });
    }, []);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            fullWidth
            open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    {targetCourse ? "Edit Course" : "Add Course"}
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={submitForm}
                        initialValues={initialValues}
                        validationSchema={courseSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box textAlign="center">
                                    <Typography variant="h6">Thumbnail Photo</Typography>
                                    <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                        {(values.thumbnail || (values.thumbnail_link && values.thumbnail_link !== "")) && <img alt='thumbnail' width="100px" src={values.thumbnail ? URL.createObjectURL(values.thumbnail) : values.thumbnail_link} />}
                                        <input id="thumbnail" name="thumbnail" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("thumbnail", event.target.files[0]); }} hidden />
                                    </Button>
                                </Box>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Title"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"title"}
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Description"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={4}
                                        name={"description"}
                                        value={values.description}
                                        error={!!touched.description && !!errors.description}
                                        helperText={touched.description && errors.description}
                                        sx={{ gridColumn: "span 2", marginTop: "10px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Details"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={4}
                                        name={"details"}
                                        value={values.details}
                                        error={!!touched.details && !!errors.details}
                                        helperText={touched.details && errors.details}
                                        sx={{ gridColumn: "span 2", marginTop: "10px" }}
                                    />

                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1", marginTop: "10px" }}>
                                        <InputLabel id="filter-label">Type</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Type"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"type"}
                                            value={values.type}
                                            error={!!touched.type && !!errors.type}
                                        >
                                            <MenuItem value={0}>Exam Preparation Courses</MenuItem>
                                            <MenuItem value={1}>Vocabulary Courses</MenuItem>
                                            <MenuItem value={2}>Grammar Courses</MenuItem>
                                            <MenuItem value={3}>Reading Courses</MenuItem>
                                            <MenuItem value={4}>Listening Courses</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.is_pro}
                                                onChange={handleChange}
                                                name="is_pro"
                                                color="primary"
                                            />
                                        }
                                        label="Only for Pro Users"
                                        sx={{ gridColumn: "span 1", marginTop: "10px" }}
                                    />

                                    <FormControl variant="filled" sx={{ gridColumn: "span 1", marginTop: "10px" }} >
                                        <InputLabel id="filter-label1">Categories</InputLabel>
                                        <Select
                                            labelId="filter-label1"
                                            label="Categories"
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                const {
                                                    target: { value },
                                                } = event;
                                                var lastValue = value[value.length - 1];
                                                var existingIndex = value.findIndex((e) => e.id === lastValue.id);
                                                if (existingIndex !== -1 && existingIndex !== value.length - 1) {
                                                    value.splice(value.length - 1, 1);
                                                    value.splice(existingIndex, 1);
                                                }
                                                setFieldValue("categories", typeof value === 'string' ? value.split(',') : value)
                                            }}
                                            multiple
                                            name={"categories"}
                                            value={values.categories}
                                            // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value.id} label={value.title} />
                                                    ))}
                                                </Box>
                                            )}
                                            error={!!touched.categories && !!errors.categories}
                                        >
                                            {courseCategories.map(({ id, title }) => (
                                                <MenuItem
                                                    key={id}
                                                    value={{ id: id, title: title }}
                                                >
                                                    {title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1", marginTop: "10px" }}>
                                        <InputLabel id="filter-label2">Language</InputLabel>
                                        <Select
                                            labelId="filter-label2"
                                            label="Language"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"language"}
                                            value={values.language}
                                            error={!!touched.language && !!errors.language}
                                        >
                                            <MenuItem value={"english"}>English</MenuItem>
                                            <MenuItem value={"turkish"}>Turkish</MenuItem>
                                            <MenuItem value={"french"}>French</MenuItem>
                                            <MenuItem value={"german"}>German</MenuItem>
                                            <MenuItem value={"spanish"}>Spanish</MenuItem>
                                            <MenuItem value={"portuguese"}>Portuguese</MenuItem>
                                            <MenuItem value={"italian"}>Italian</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="filled" sx={{ gridColumn: "span 2", marginTop: "10px" }} >
                                        <InputLabel id="filter-label3">Instructors</InputLabel>
                                        <Select
                                            labelId="filter-label3"
                                            label="Instructors"
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                const {
                                                    target: { value },
                                                } = event;
                                                var lastValue = value[value.length - 1];
                                                var existingIndex = value.findIndex((e) => e.id === lastValue.id);
                                                if (existingIndex !== -1 && existingIndex !== value.length - 1) {
                                                    value.splice(value.length - 1, 1);
                                                    value.splice(existingIndex, 1);
                                                }
                                                setFieldValue("instructors", typeof value === 'string' ? value.split(',') : value)
                                            }}
                                            multiple
                                            name={"instructors"}
                                            value={values.instructors}
                                            // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value.id} label={
                                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                <img src={value.profile_photo_link} alt="profile" style={{ width: "20px", height: "20px", borderRadius: "50%" }} />
                                                                <p>
                                                                    {value.name}
                                                                </p>
                                                            </div>
                                                        } />
                                                    ))}
                                                </Box>
                                            )}
                                            error={!!touched.instructors && !!errors.instructors}
                                        >
                                            {allInstructors.map(({ id, name, profile_photo_link }) => (
                                                <MenuItem
                                                    key={id}
                                                    value={{ id: id, name: name, profile_photo_link: profile_photo_link }}
                                                >
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <img src={profile_photo_link} alt="profile" style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
                                                        <p>
                                                            {name}
                                                        </p>
                                                    </div>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        disabled={isUploading}
                                        onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Save</Typography>
                                        {
                                            isUploading &&
                                            <CircularProgress size={16} sx={{ ml: "10px" }} />
                                        }
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default CourseDialog